const invokeUrl =
  'https://nn05rt4c4l.execute-api.us-east-2.amazonaws.com/v1/quicksightresource'
const awsRegion = 'us-east-2'

export const quicksightDashboardList = {
  executiveDashboard: {
    title: 'Executive Dashboard',
    baseUrl: invokeUrl,
    dashboardId: '22595618-3a54-42de-96d2-08f6e8ec4d58',
    dashboardRegion: awsRegion,
    dashboardName: 'Executive%20Dashboard',
  },
  carbonFinance: {
    title: 'Carbon Finance',
    baseUrl: invokeUrl,
    dashboardId: '6335c30f-087f-4c90-a831-5f3f561629ef',
    dashboardRegion: awsRegion,
    dashboardName: 'Carbon%20Finance',
  },
  reclaimedWater: {
    title: 'Reclaimed Water',
    baseUrl: invokeUrl,
    dashboardId: 'd12dd49e-85e1-452c-a1e7-77696e8d4803',
    dashboardRegion: awsRegion,
    dashboardName: 'Reclaimed%Water%Dashboard',
  },
  productionVolume: {
    title: 'Production Volume',
    baseUrl: invokeUrl,
    dashboardId: 'cecdb4f8-143b-42cc-8c48-0981bede5bde',
    dashboardRegion: awsRegion,
    dashboardName: 'Production%Volume',
  },
  customerSuccess: {
    title: 'Customer Success',
    baseUrl: invokeUrl,
    dashboardId: 'edf769f7-05c9-4214-8ff9-a93b135c06af',
    dashboardRegion: awsRegion,
    dashboardName: 'Customer%Success',
  },
  customerOperations: {
    title: 'Customer Operations',
    baseUrl: invokeUrl,
    dashboardId: 'a1f44821-1c51-48e3-b4ea-97e6dc04f022',
    dashboardRegion: awsRegion,
    dashboardName: 'Customer%Operations',
  },
}

export const dashboardNameList = Object.keys(quicksightDashboardList).map(
  name => name
)
