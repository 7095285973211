import { useCallback, useState, useEffect, useRef, useMemo } from 'react'
import { useRecoilValue } from 'recoil'
import downSystemsHelper from './DownSystemsHelper'
import { ISonarAlarm, TSortOrder } from '../../../Common/Logic/Types'
import {
  ALARMS_DATA_DOWNLOAD_THRESHOLD,
  REFRESH_INTERVAL_ALARMS,
} from '../../../Hardware/Sonar/Constants/SonarConstants'
import alarmsHelper from './AlarmsHelper'
import { atomSonarFilter } from '../../../Common/atoms'
import alarmsTableHelper from './AlarmsTable/AlarmsTableHelper'
import downSystemsTableHelper from './DownSystemsTable/DownSystemsTableHelper'

export const useAlarms = () => {
  // State and Setters
  const [lastRefreshed, setLastRefreshed] = useState<string>(
    downSystemsHelper.formatTime(new Date()) // Initial state set with formatted time
  )

  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [order, setOrder] = useState<TSortOrder>('desc')
  const [orderBy, setOrderBy] = useState<string>('timestamp')
  const [page, setPage] = useState<number>(0)
  const [rowsPerPage, setRowsPerPage] = useState<number>(10)
  const [tableCount, setTableCount] = useState<number>(0)
  const [alarmsData, setAlarmsData] = useState<ISonarAlarm[]>([])
  const [snoozeSavedFlag, setSnoozeSavedFlag] = useState<boolean>(false)
  const [disableDownloadButton, setDisableDownloadButton] = useState<boolean>(
    true
  )

  const sonarFilter = useRecoilValue(atomSonarFilter)
  const [csvFilename, setCsvFilename] = useState<string>(
    downSystemsHelper.defineSonarDataFileName(sonarFilter, 'alarms')
  )
  const [alarmsDownloadData, setAlarmsDownloadData] = useState<ISonarAlarm[]>(
    []
  )
  const [isDownloadLoading, setIsDownloadLoading] = useState<boolean>(false)
  const [initiateDownload, setInitiateDownload] = useState(false)
  const csvRef = useRef(null)
  const stringifiedSonarFilter = useMemo(() => JSON.stringify(sonarFilter), [
    sonarFilter,
  ])

  // Callbacks
  const retrieveAlarmsData = useCallback(
    async abortController => {
      const parsedSonarFilter = JSON.parse(stringifiedSonarFilter)
      if (
        !alarmsHelper.areDateFiltersValid(
          parsedSonarFilter.lastSeenDateRange,
          parsedSonarFilter.lastSeenStartDate,
          parsedSonarFilter.lastSeenEndDate
        )
      )
        return
      const { count, results } = await alarmsHelper.fetchAlarmsData(
        rowsPerPage,
        page * rowsPerPage,
        order,
        orderBy,
        parsedSonarFilter,
        setIsLoading,
        abortController
      )

      const digestedAlarmsData = alarmsHelper.digestAlarmsData(results)
      setTableCount(count)
      setAlarmsData(digestedAlarmsData)
      setLastRefreshed(downSystemsHelper.formatTime(new Date()))
      setDisableDownloadButton(count > ALARMS_DATA_DOWNLOAD_THRESHOLD.value)
    },
    [order, orderBy, page, rowsPerPage, stringifiedSonarFilter]
  )

  const onChangePage = useCallback(
    (_event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
      setPage(newPage)
    },
    []
  )

  const retrieveAlarmsDataDownload = useCallback(async () => {
    const fileName = downSystemsHelper.defineSonarDataFileName(
      sonarFilter,
      'alarms'
    )
    setCsvFilename(fileName)

    const { results } = await alarmsHelper.fetchAlarmsData(
      tableCount,
      0,
      order,
      orderBy,
      sonarFilter,
      setIsDownloadLoading
    )

    // Set the isSnoozed property based on the snoozeId and remove the snoozeId from the download data
    const updatedResults = results.map((result: ISonarAlarm) => {
      const { snoozeId, ...rest } = result
      return {
        ...rest,
        snoozeId,
        snoozed: String(snoozeId !== null),
        snoozeReason: alarmsTableHelper.getAlarmSnoozeReasonLabel(
          result.snoozeReason
        ),
        technology: downSystemsTableHelper.convertTechnologyType(
          result.technology
        ),
      }
    })
    setAlarmsDownloadData(updatedResults)
    setInitiateDownload(true)
  }, [order, orderBy, sonarFilter, tableCount])

  const onChangeRowsPerPage = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setRowsPerPage(parseInt(event.target.value))
      setPage(0)
    },
    []
  )

  const onColumnHeaderClick = useCallback(
    (_event: React.MouseEvent<HTMLSpanElement, MouseEvent>, column: string) => {
      // Handle the sorting logic
      const isAsc = orderBy === column && order === 'asc'
      setOrder(isAsc ? 'desc' : 'asc')
      setOrderBy(column)
    },
    [order, orderBy]
  )

  // Effects
  useEffect(() => {
    const interval = setInterval(() => {
      setLastRefreshed(downSystemsHelper.formatTime(new Date()))
    }, REFRESH_INTERVAL_ALARMS)

    return () => clearInterval(interval)
  }, [])

  useEffect(() => {
    const abortController = new AbortController()

    const interval = setInterval(() => {
      retrieveAlarmsData(abortController)
    }, REFRESH_INTERVAL_ALARMS)

    return () => {
      abortController.abort()
      clearInterval(interval)
    }
  }, [retrieveAlarmsData])

  useEffect(() => {
    const abortController = new AbortController()
    retrieveAlarmsData(abortController)

    return () => {
      abortController.abort()
    }
  }, [retrieveAlarmsData, snoozeSavedFlag])

  // Reset pagination when sonarFilter changes
  useEffect(() => {
    setPage(0)
  }, [sonarFilter, setPage])

  useEffect(() => {
    if (initiateDownload && csvRef?.current) {
      // @ts-ignore
      csvRef.current.link.click()
      setInitiateDownload(false)
    }
  }, [initiateDownload])

  return {
    alarmsData,
    alarmsDownloadData,
    csvFilename,
    csvRef,
    disableDownloadButton,
    isDownloadLoading,
    isLoading,
    lastRefreshed,
    onChangePage,
    onChangeRowsPerPage,
    onColumnHeaderClick,
    order,
    orderBy,
    page,
    retrieveAlarmsDataDownload,
    rowsPerPage,
    setSnoozeSavedFlag,
    tableCount,
  }
}

const hook = {
  useAlarms,
}

export default hook
