import { Dispatch, SetStateAction } from 'react'
import {
  ConvertCtoFUnrounded,
  ConvertMMtoINCH,
  ConvertMMtoINCHUnrounded,
  ConvertMPAToPSIUnrounded,
  ConvertMPaKgToPSILbUnrounded,
  convertKgM3ToLbYd3Unrounded,
} from '../../Common/Helpers/GeneralHelpers'
import {
  translatedCementTypes,
  translatedScmTypes,
} from '../../Echo/Constants/Constants'
import { IMixSelectionProperty } from '../Components/CommissionReportMixDesignDetails'
import { mixSelectionTestCategories } from '../Constants/AddDataConstants'
import {
  defaultDigestedVariation,
  defaultSelectedTestResultsProperties,
} from '../Constants/CommissionReportConstants'
import { deleteCommissionReport } from '../Data/TSSDataHelpers'
import {
  findVariationByType,
  getCO2DosageUnitLabel,
  getFormattedDate,
} from '../Logic/TSSLogic'
import {
  ICommissionReportSettings,
  ICommissionReportVariation,
  ICommissionReportSample,
  IMixSelection,
  IReportMixSelectionSettings,
  KelownaBaleenMixGroup,
  KelownaBaleenMixVariation,
  NumericIntervals,
  VariationTypes,
  ICommissionReportVariatonStrengths,
  TestCategoryOption,
  TestCategoriesKey,
  KelownaSampleStrengths,
  ICommissionReportSampleStrengths,
  KelownaCommissionReportSettings,
  PlantType,
  KelownaMixSelection,
  PropertyUnit,
  ICommissionReportFrequencyGraphSettings,
  ICommissionReportAvgStrengthGraphSettings,
  ICommissionReportVariationOption,
  ITestResultsPropertyOption,
  SummarySingleData,
  SummaryMultipleData,
  IViewMixSelectionProperty,
  insufficientSamplesBannerType,
  IInsufficientVariationSamples,
  IInsufficientMaturityAgeSamples,
  ICommissionReportSaveReqBody,
  CommissionReportOutlierRow,
} from '../Logic/Types'
import {
  getCO2Dosage,
  getCO2DosagePrecision,
  getIntervalString,
  outlierReasonOptions,
} from './BaleenHelpers'

export const digestReportSettings = (data: KelownaCommissionReportSettings) => {
  const reportSettings: ICommissionReportSettings = {
    producerId: data.divisionId,
    plantId: data.plantId,
    isMetric: data.isMetric,
    reportStatus: data.status,
    testingStage: data.testingStage,
    lastUpdated: getFormattedDate(
      data.lastEditedTimestamp,
      'YYYYMMDD',
      '-',
      true
    ),
    createdBy: data.createdBy,
    createdByUserEmail: data.createdByUserEmail,
    createDate: getFormattedDate(data.createdTimestamp, 'YYYYMMDD', '-', true),
    lastUpdatedBy: data.lastEditedBy,
    plantType: data.plantType as PlantType,
    testingSummary: data.testingSummary,
    conclusionParagraph: data.conclusionParagraph,
    mixDesignSettings: [],
  }
  // Iterate over mixSelection and populate mixDesignSettings
  data.mixSelection.forEach((mixSelection: KelownaMixSelection) => {
    reportSettings.mixDesignSettings.push(
      digestMixSelectionSettings(mixSelection)
    )
  })

  return reportSettings
}

export const digestMixSelectionSettings = (
  mixSelection: KelownaMixSelection
) => {
  const { settings } = mixSelection
  const reportMixSelectionSettings: IReportMixSelectionSettings = {
    mixDesignId: mixSelection.mixDesignId,
    mixDesignPropertiesIncludedOnCustomerReport: {
      designStrength: settings.propertiesToInclude.includeDesignStrength, //always true
      waterCementRatio: settings.propertiesToInclude.includeWaterCementRatio,
      airContentRange: settings.propertiesToInclude.includeAirContentRange,
      slumpRange: settings.propertiesToInclude.includeSlump,
      scmTypes: settings.propertiesToInclude.includeScmTypes,
      scmPercent: settings.propertiesToInclude.includeScmPercent,
      cementTypes: settings.propertiesToInclude.includeCementType,
      cementContent: settings.propertiesToInclude.includeCementContent,
    },
    testResults: {
      selectedProperties: mixSelection.testResultProperties,
      selectedStrengthHours: mixSelection.selectedStrengthHours,
      selectedStrengthStDevHours: mixSelection.selectedStrengthStDevHours,
      showDifference: mixSelection.settings.showDifference,
    },
    frequencyGraph: {
      isSelected: settings.frequencyGraphSettings.isSelected,
      selectedInterval: settings.frequencyGraphSettings.selectedIntervalHours,
      showFailureZone: settings.frequencyGraphSettings.showFailureZone,
      showHistogram: settings.frequencyGraphSettings.showHistogram,
      showBellCurve: settings.frequencyGraphSettings.showBellCurve,
    },
    avgStrengthGraph: {
      isSelected: settings.avgStrengthGraphSettings.isSelected,
      selectedInterval: settings.avgStrengthGraphSettings.selectedIntervalHours,
      showDesignStrength: settings.avgStrengthGraphSettings.showDesignStrength,
    },
    outlierSettings: {
      show: settings.showOutliers,
      showReason: settings.showOutlierReason,
    },
    baselineVariation: {
      selectedTestCategories:
        mixSelection.baselineVariation.selectedTestCategories,
      cO2Dosage: mixSelection.baselineVariation.cO2Dosage,
      cO2DosageUnit: mixSelection.baselineVariation.cO2DosageUnit,
      cementReductionPercent:
        mixSelection.baselineVariation.cementReductionPercent,
    },
    carbonCureVariation: {
      selectedTestCategories:
        mixSelection.carbonCureVariation.selectedTestCategories,
      cO2Dosage: mixSelection.carbonCureVariation.cO2Dosage,
      cO2DosageUnit: mixSelection.carbonCureVariation.cO2DosageUnit,
      cementReductionPercent:
        mixSelection.carbonCureVariation.cementReductionPercent,
    },
  }

  return reportMixSelectionSettings
}

export const getSlumpValue = (slump: number | null, isMetric: boolean) => {
  if (slump === null) return null
  return isMetric
    ? Number(slump.toFixed(0))
    : Number(ConvertMMtoINCH(slump)?.toFixed(0))
}

export const getVariationCementEfficiency = (
  variation: ICommissionReportVariation,
  strengthIntervalHours: NumericIntervals | null
) => {
  if (strengthIntervalHours === null) return null
  const strength = variation.strengths[strengthIntervalHours]?.strength
  const cementContent = variation.cementContent

  return strength && cementContent && cementContent > 0
    ? strength / cementContent
    : null
}

export const digestMixSelection = (
  data: KelownaBaleenMixGroup,
  reportSettings: ICommissionReportSettings
) => {
  const strengthIntervalHours = data.strengthIntervalDays
    ? ((data.strengthIntervalDays * 24) as NumericIntervals)
    : null
  const digestedVariations = digestAllVariations(
    data,
    reportSettings,
    strengthIntervalHours
  )
  const digestedMixSelection: IMixSelection = {
    mixDesignId: data.mixDesignId,
    mixCode: data.mixCode,
    designStrength: reportSettings.isMetric
      ? data.designStrengthMpa
      : ConvertMPAToPSIUnrounded(data.designStrengthMpa),
    strengthIntervalHours: strengthIntervalHours,
    waterCementRatio: data.waterCementRatio,
    airContentRange: getRange(
      data.airContentPercentageMin,
      data.airContentPercentageMax,
      'air',
      reportSettings.isMetric
    ),
    slumpRange: getRange(
      data.slumpMmMin,
      data.slumpMmMax,
      'slump',
      reportSettings.isMetric
    ),
    scmTypes: data.scmTypes ? translateScmTypes(data.scmTypes) : null,
    scmPercent: data.scmPercent ?? null,
    cementTypes: data.cementTypes
      ? translateCementTypes(data.cementTypes)
      : null,
    cementContent: reportSettings.isMetric
      ? data.cementitiousContentKgPerM3
      : convertKgM3ToLbYd3Unrounded(data.cementitiousContentKgPerM3),
    testResultsPropertyOptions: getTestResultsPropertyOptions(
      reportSettings.isMetric
    ),
    variationOptions: getVariationOptions(digestedVariations),
    digestedVariations: digestedVariations,
    intervalOptions: getMixGroupIntervalOptions(digestedVariations),
    specifiedMaturityAge: strengthIntervalHours,
  }
  return digestedMixSelection
}

export const getEstimatedVariations = (
  digestedVariations: ICommissionReportVariation[],
  hasBaseline: boolean,
  hasOptimized: boolean
) => {
  if (!hasBaseline) {
    //Match with kelowna's baseline
    const kelownaBaseline = digestedVariations.find(
      variation => variation.variationType === VariationTypes.BASELINE
    )
    if (kelownaBaseline) {
      kelownaBaseline.orcaVariationType = VariationTypes.BASELINE
    }
    //Get the lowest co2Dose or cement cut as baseline
    else getEstimatedVariation(digestedVariations, VariationTypes.BASELINE)
  }
  if (!hasOptimized) {
    //Match with kelowna's optimized
    const kelownaOptimized = digestedVariations.find(
      variation => variation.variationType === VariationTypes.OPTIMIZED
    )
    if (kelownaOptimized) {
      kelownaOptimized.orcaVariationType = VariationTypes.OPTIMIZED
    }
    //Get the highest co2Dose or cement cut as optimized
    else getEstimatedVariation(digestedVariations, VariationTypes.OPTIMIZED)
  }
}

export const digestAllVariations = (
  data: KelownaBaleenMixGroup,
  reportSettings: ICommissionReportSettings,
  strengthIntervalHours: NumericIntervals | null
) => {
  const mixDesignId = data.mixDesignId
  const settings = reportSettings.mixDesignSettings.find(
    setting => setting.mixDesignId === mixDesignId
  )
  const undigestedVariations = data.mixVariations
  if (!settings || undigestedVariations.length < 2) return []
  const digestedVariations: ICommissionReportVariation[] = []
  for (const variation of undigestedVariations) {
    const cO2DosageLabel = getCO2Dosage(
      variation.cO2Dosage,
      variation.cO2DosageUnit,
      reportSettings.isMetric
    )
    const precision = getCO2DosagePrecision(
      variation.cO2DosageUnit,
      reportSettings.isMetric
    )
    const digestedCO2Dosage =
      cO2DosageLabel === null ? null : cO2DosageLabel.toFixed(precision)
    const cO2DosageUnitLabel =
      getCO2DosageUnitLabel(variation.cO2DosageUnit, reportSettings.isMetric) ||
      ''
    const variationId = `${mixDesignId}-${variation.cementReductionPercent}-${variation.cO2Dosage}${cO2DosageUnitLabel}`
    const variationIdLabel = `${data.mixCode}-${variation.cementReductionPercent}-${digestedCO2Dosage}${cO2DosageUnitLabel}`

    const digestedSamples = digestMixSelectionSamples(
      variation,
      mixDesignId,
      variationId,
      variationIdLabel,
      reportSettings.isMetric
    )

    const digestedVariation: ICommissionReportVariation = {
      ...defaultDigestedVariation,
      mixDesignId: mixDesignId,
      variationId: variationId,
      variationIdLabel: variationIdLabel,
      cO2Dosage: variation.cO2Dosage,
      cO2DosageLabel: digestedCO2Dosage,
      cO2DosageUnit: variation.cO2DosageUnit ?? null,
      cementReduction: variation.cementReductionPercent ?? null,
      cementEfficiency: variation.cementitiousEfficiency ?? null,
      samples: digestedSamples,
      testCategoryOptions: getTestCategoryOptions(digestedSamples),
      variationType: variation.variationType,
      orcaVariationType: getOrcaVariationType(variation, settings),
    }

    digestedVariations.push(digestedVariation)
  }

  const hasBaseline = digestedVariations.find(
    variation => variation.orcaVariationType === VariationTypes.BASELINE
  )
  const hasOptimized = digestedVariations.find(
    variation => variation.orcaVariationType === VariationTypes.OPTIMIZED
  )

  getEstimatedVariations(digestedVariations, hasBaseline, hasOptimized)

  //Only get avergaes and standard deviations for Baseline / Optimized
  const digestedBaslineVariation = digestedVariations.find(
    variation => variation.orcaVariationType === VariationTypes.BASELINE
  )!
  computeAverageAndStdDev(
    digestedBaslineVariation,
    settings,
    strengthIntervalHours
  )

  const digestedOptimizedVariation = digestedVariations.find(
    variation => variation.orcaVariationType === VariationTypes.OPTIMIZED
  )!
  computeAverageAndStdDev(
    digestedOptimizedVariation,
    settings,
    strengthIntervalHours
  )
  return digestedVariations
}

export const computeAverageAndStdDev = (
  variation: ICommissionReportVariation,
  settings: IReportMixSelectionSettings,
  strengthIntervalHours: NumericIntervals | null
) => {
  variation.slump = getVariationAverage(
    variation.samples,
    'slump',
    settings,
    variation.orcaVariationType
  )
  variation.slumpStdDev = getVariationStdDev(
    variation.samples,
    'slump',
    settings,
    variation.orcaVariationType
  )
  variation.airContent = getVariationAverage(
    variation.samples,
    'airContent',
    settings,
    variation.orcaVariationType
  )
  variation.airStdDev = getVariationStdDev(
    variation.samples,
    'airContent',
    settings,
    variation.orcaVariationType
  )
  variation.unitWeight = getVariationAverage(
    variation.samples,
    'unitWeight',
    settings,
    variation.orcaVariationType
  )
  variation.cementContent = getVariationAverage(
    variation.samples,
    'cementContent',
    settings,
    variation.orcaVariationType
  )
  variation.concreteTemperature = getVariationAverage(
    variation.samples,
    'temperature',
    settings,
    variation.orcaVariationType
  )
  variation.strengths = getVariationStrengths(
    variation.samples,
    settings,
    variation.orcaVariationType
  )

  variation.cementEfficiency = getVariationCementEfficiency(
    variation,
    strengthIntervalHours
  )

  variation.scmLoading = getVariationAverage(
    variation.samples,
    'scmLoading',
    settings,
    variation.orcaVariationType
  )
}

export const getOrcaVariationType = (
  variation: KelownaBaleenMixVariation,
  settings: IReportMixSelectionSettings
) => {
  const { baselineVariation, carbonCureVariation } = settings

  if (
    (baselineVariation &&
      variation.cO2Dosage === baselineVariation.cO2Dosage &&
      variation.cO2DosageUnit === baselineVariation.cO2DosageUnit &&
      variation.cementReductionPercent ===
        baselineVariation.cementReductionPercent) ||
    (!baselineVariation && variation.variationType === VariationTypes.BASELINE)
  ) {
    return VariationTypes.BASELINE
  } else if (
    (carbonCureVariation &&
      variation.cO2Dosage === carbonCureVariation.cO2Dosage &&
      variation.cO2DosageUnit === carbonCureVariation.cO2DosageUnit &&
      variation.cementReductionPercent ===
        carbonCureVariation.cementReductionPercent) ||
    (!carbonCureVariation &&
      variation.variationType === VariationTypes.OPTIMIZED)
  ) {
    return VariationTypes.OPTIMIZED
  } else {
    return null
  }
}

/**
 * Function to get the estimated baseline variation based on the lowest CO2 dosage
 * @param digestedVariations
 * @returns Returns a variation or undefined
 */
export const getBaselineEstimateBasedOnCO2Dosage = (
  digestedVariations: ICommissionReportVariation[]
) => {
  let lowestCO2Dosage = Infinity
  let baselineVariation

  for (const variation of digestedVariations) {
    if (
      variation.cO2Dosage !== null &&
      variation.cO2Dosage < lowestCO2Dosage &&
      !variation.orcaVariationType
    ) {
      lowestCO2Dosage = variation.cO2Dosage
      baselineVariation = variation
    }
  }
  return baselineVariation
}

/**
 * Function to get the estimated baseline variation based on lowest cement reduction
 * @param digestedVariations
 * @returns Returns a variation or undefined
 */
export const getBaselineEstimateBasedOnCemRed = (
  digestedVariations: ICommissionReportVariation[]
) => {
  let lowestCementReduction = Infinity
  let baselineVariation
  for (const variation of digestedVariations) {
    if (
      variation.cementReduction !== null &&
      variation.cementReduction < lowestCementReduction &&
      !variation.orcaVariationType
    ) {
      lowestCementReduction = variation.cementReduction
      baselineVariation = variation
    }
  }
  return baselineVariation
}

/**
 * Function to get the estimated CarbonCure variation based on highest CO2 Dosage
 * @param digestedVariations
 * @returns returns a variation or undefined
 */
export const getCarbonCureEstimateBasedOnCO2Dosage = (
  digestedVariations: ICommissionReportVariation[]
) => {
  let highestCO2Dosage = -Infinity
  let carbonCureVariation

  for (const variation of digestedVariations) {
    if (
      variation.cO2Dosage !== null &&
      variation.cO2Dosage > highestCO2Dosage &&
      !variation.orcaVariationType
    ) {
      highestCO2Dosage = variation.cO2Dosage
      carbonCureVariation = variation
    }
  }
  return carbonCureVariation
}

/**
 * Function to get the estimated CarbonCure variation based on highest cement reduction
 * @param digestedVariations
 * @returns returns a variation or undefined.
 */
export const getCarbonCureEstimateBasedOnCemRed = (
  digestedVariations: ICommissionReportVariation[]
) => {
  //find the variation with highest cement cut
  let highestCementReduction = -Infinity
  let carbonCureVariation

  for (const variation of digestedVariations) {
    if (
      variation.cementReduction !== null &&
      variation.cementReduction > highestCementReduction &&
      !variation.orcaVariationType
    ) {
      highestCementReduction = variation.cementReduction
      carbonCureVariation = variation
    }
  }
  return carbonCureVariation
}

/**
 * Function to select the default baseline variation
 * @param {ICommissionReportVariation[]} digestedVariations
 */
export const getEstimatedBaseline = (
  digestedVariations: ICommissionReportVariation[]
) => {
  let baselineVariation = getBaselineEstimateBasedOnCO2Dosage(
    digestedVariations
  )
  if (baselineVariation) {
    baselineVariation.orcaVariationType = VariationTypes.BASELINE
  } else {
    //find the variation with lowest cement cut
    baselineVariation = getBaselineEstimateBasedOnCemRed(digestedVariations)
    if (baselineVariation) {
      baselineVariation.orcaVariationType = VariationTypes.BASELINE
    } else {
      //assign a variation regardless of co2dose/ cement cut
      baselineVariation = digestedVariations[0].orcaVariationType
        ? digestedVariations[1]
        : digestedVariations[0]
      baselineVariation.orcaVariationType = VariationTypes.BASELINE
    }
  }
}
/**
 * Function to select the default CarbonCure variation
 * @param {ICommissionReportVariation[]} digestedVariations
 */
export const getEstimatedCarbonCure = (
  digestedVariations: ICommissionReportVariation[]
) => {
  let carbonCureVariation = getCarbonCureEstimateBasedOnCO2Dosage(
    digestedVariations
  )

  if (carbonCureVariation) {
    carbonCureVariation.orcaVariationType = VariationTypes.OPTIMIZED
  } else {
    //find the variation with highest cement cut
    carbonCureVariation = getCarbonCureEstimateBasedOnCemRed(digestedVariations)
    if (carbonCureVariation) {
      carbonCureVariation.orcaVariationType = VariationTypes.OPTIMIZED
    } else {
      //assign a variation regardless of co2dose/ cement cut
      carbonCureVariation = digestedVariations[0].orcaVariationType
        ? digestedVariations[1]
        : digestedVariations[0]
      carbonCureVariation.orcaVariationType = VariationTypes.OPTIMIZED
    }
  }
}

export const getEstimatedVariation = (
  digestedVariations: ICommissionReportVariation[],
  type: VariationTypes
) => {
  if (type === VariationTypes.BASELINE) {
    getEstimatedBaseline(digestedVariations)
  } else if (type === VariationTypes.OPTIMIZED) {
    getEstimatedCarbonCure(digestedVariations)
  }
}

export const getScmLoading = (
  cementitiousContent: number | null,
  scmPercent: number | null,
  isMetric: boolean
) => {
  if (cementitiousContent === null || scmPercent === null) return null
  const scmLoading = cementitiousContent * (scmPercent / 100) // Convert percentage to decimal
  return isMetric ? scmLoading : convertKgM3ToLbYd3Unrounded(scmLoading)
}

export const digestMixSelectionSamples = (
  mixSelectionVariation: KelownaBaleenMixVariation,
  mixDesignId: number,
  variationId: string,
  variationIdLabel: string,
  isMetric: boolean
): ICommissionReportSample[] => {
  const mixSelectionSamples: ICommissionReportSample[] = []

  for (const sample of mixSelectionVariation.samples) {
    const mixSelectionSample: ICommissionReportSample = {
      mixDesignId: mixDesignId,
      batchId: sample.batchSampleId,
      ticketId: sample.externalTicketId,
      variationId: variationId,
      variationIdLabel: variationIdLabel,
      cO2Dosage: getCO2Dosage(sample.cO2Dosage, sample.cO2DosageUnit, isMetric),
      cO2DosageUnit: getCO2DosageUnitLabel(sample.cO2DosageUnit) ?? null,
      cementReduction: sample.cementReduction ?? null,
      slump: isMetric
        ? sample.slumpMm
        : ConvertMMtoINCHUnrounded(sample.slumpMm),
      airContent: sample.airContent ?? null,
      cementEfficiency: isMetric
        ? sample.cementitiousEfficiency
        : ConvertMPaKgToPSILbUnrounded(sample.cementitiousEfficiency),
      unitWeight: isMetric
        ? sample.densityKgPerM3
        : convertKgM3ToLbYd3Unrounded(sample.densityKgPerM3),
      cementContent: isMetric
        ? sample.cementContentKgPerM3
        : convertKgM3ToLbYd3Unrounded(sample.cementContentKgPerM3),
      temperature: isMetric
        ? sample.concreteTemperature
        : ConvertCtoFUnrounded(sample.concreteTemperature),
      strengths: digestSampleStrengths(sample.hourlyStrengthAvg, isMetric),
      isOutlier: !!sample.outlierType,
      outlierReason: sample.outlierReason ?? null,
      testCategory: sample.testCategory ?? null,
      scmLoading: getScmLoading(
        sample.cementitiousContentKgPerM3,
        sample.scmPercent,
        isMetric
      ),
    }
    mixSelectionSamples.push(mixSelectionSample)
  }
  return mixSelectionSamples
}

export const getVariationAverage = (
  digestedSamples: ICommissionReportSample[],
  property: keyof ICommissionReportSample,
  settings: IReportMixSelectionSettings,
  orcaVariationType: VariationTypes | null
) => {
  let totalValue = 0
  let totalCount = 0
  let selectedTestCategories: TestCategoriesKey[] = []

  if (
    settings.baselineVariation &&
    settings.carbonCureVariation &&
    orcaVariationType
  ) {
    selectedTestCategories =
      orcaVariationType === VariationTypes.BASELINE
        ? settings.baselineVariation.selectedTestCategories
        : settings.carbonCureVariation.selectedTestCategories
  }

  for (const sample of digestedSamples) {
    if (isValidSample(sample, property, selectedTestCategories)) {
      totalValue += Number(sample[property])
      totalCount++
    }
  }

  if (totalCount === 0) return null
  return totalValue / totalCount
}

export const getVariationStdDev = (
  digestedSamples: ICommissionReportSample[],
  property: keyof ICommissionReportSample,
  settings: IReportMixSelectionSettings,
  orcaVariationType: VariationTypes | null
) => {
  const variationAverage = getVariationAverage(
    digestedSamples,
    property,
    settings,
    orcaVariationType
  )
  if (!variationAverage) return null

  let totalDev = 0
  let totalCount = 0
  let selectedTestCategories: TestCategoriesKey[] = []
  if (
    settings.baselineVariation &&
    settings.carbonCureVariation &&
    orcaVariationType
  ) {
    selectedTestCategories =
      orcaVariationType === VariationTypes.BASELINE
        ? settings.baselineVariation.selectedTestCategories
        : settings.carbonCureVariation.selectedTestCategories
  }

  for (const sample of digestedSamples) {
    if (isValidSample(sample, property, selectedTestCategories)) {
      const currentValue = Number(sample[property])
      const currentDev = (currentValue - variationAverage) ** 2
      totalDev += currentDev
      totalCount++
    }
  }
  if (totalCount < 2) return null //std dev must have at least two data points
  const variationStdDev = Math.sqrt(totalDev / (totalCount - 1)) // n-1 sample standard deviation
  return variationStdDev
}

export const getStDevStrength = (
  samples: ICommissionReportSample[],
  interval: NumericIntervals,
  avgStrength: number
) => {
  if (samples.length < 2) return null //std dev must have at least two data points

  const totalDev = samples.reduce(
    (acc, sample) => acc + (sample.strengths[interval] - avgStrength) ** 2,
    0
  )
  const stDevStrength = Math.sqrt(totalDev / (samples.length - 1)) // n-1 sample standard deviation
  return stDevStrength
}

export const getVariationStrengths = (
  digestedSamples: ICommissionReportSample[],
  settings: IReportMixSelectionSettings,
  orcaVariationType: VariationTypes | null
) => {
  const variatonStrengths: ICommissionReportVariatonStrengths = {}
  let selectedTestCategories: TestCategoriesKey[] = []
  if (
    settings.baselineVariation &&
    settings.carbonCureVariation &&
    orcaVariationType
  )
    selectedTestCategories =
      orcaVariationType === VariationTypes.BASELINE
        ? settings.baselineVariation.selectedTestCategories
        : settings.carbonCureVariation.selectedTestCategories

  const intervalOptions = getVariationIntervalOptions(
    digestedSamples,
    settings,
    orcaVariationType
  )
  const samplesWithoutOutliers = digestedSamples.filter(
    sample => !sample.isOutlier
  )

  for (const interval of intervalOptions) {
    const samplesWithInterval = samplesWithoutOutliers.filter(
      sample =>
        sample.strengths[interval] &&
        (!selectedTestCategories.length ||
          (sample.testCategory !== null &&
            selectedTestCategories.includes(sample.testCategory)))
    )

    if (samplesWithInterval.length > 0) {
      const totalStrength = samplesWithInterval.reduce(
        (acc, sample) => acc + sample.strengths[interval],
        0
      )
      const avgStrength = totalStrength / samplesWithInterval.length
      variatonStrengths[interval] = {
        strength: avgStrength,
        stDev: getStDevStrength(samplesWithInterval, interval, avgStrength),
      }
    }
  }
  return variatonStrengths
}

export const digestSampleStrengths = (
  sampleStrengths: KelownaSampleStrengths,
  isMetric: boolean
) => {
  const digestedStrengths: ICommissionReportSampleStrengths = {}

  Object.keys(sampleStrengths).forEach((key: string) => {
    const numericKey = Number(key) as NumericIntervals
    if (sampleStrengths[numericKey].averageMpa)
      digestedStrengths[numericKey] = isMetric
        ? sampleStrengths[numericKey].averageMpa
        : (ConvertMPAToPSIUnrounded(
            sampleStrengths[numericKey].averageMpa
          ) as number)
  })

  return digestedStrengths
}

export const getTestCategoryOptions = (
  digestedSamples: ICommissionReportSample[]
) => {
  const uniqueOptions = new Set<TestCategoriesKey>()
  const testCategoryOptions: TestCategoryOption[] = []

  for (const sample of digestedSamples) {
    //excluding outliers
    if (!sample.isOutlier && sample.testCategory) {
      uniqueOptions.add(sample.testCategory)
    }
  }
  if (uniqueOptions.size > 0) {
    if (uniqueOptions.size > 1)
      testCategoryOptions.push({
        id: 'all',
        label: `All (${uniqueOptions.size})`,
      })
    Array.from(uniqueOptions).forEach(option => {
      testCategoryOptions.push({
        id: option,
        label: mixSelectionTestCategories[option],
      })
    })
  }
  return testCategoryOptions
}

export const getMixGroupIntervalOptions = (
  digestedVariations: ICommissionReportVariation[]
) => {
  const intervalOptions = new Set<NumericIntervals>()

  //only get baseline/optimized intervals
  const baselineVariation = digestedVariations.find(
    variation => variation.orcaVariationType === VariationTypes.BASELINE
  )
  const optimizedVariation = digestedVariations.find(
    variation => variation.orcaVariationType === VariationTypes.OPTIMIZED
  )

  if (baselineVariation) {
    const baselineIntervals = Object.keys(baselineVariation.strengths)
    for (const interval of baselineIntervals) {
      intervalOptions.add(Number(interval) as NumericIntervals)
    }
  }
  if (optimizedVariation) {
    const optimizedIntervals = Object.keys(optimizedVariation.strengths)
    for (const interval of optimizedIntervals) {
      intervalOptions.add(Number(interval) as NumericIntervals)
    }
  }
  const sortedIntervalOptions = Array.from(intervalOptions).sort(
    (a, b) => a - b
  )
  return sortedIntervalOptions
}

export const getVariationIntervalOptions = (
  digestedSamples: ICommissionReportSample[],
  settings: IReportMixSelectionSettings,
  orcaVariationType: VariationTypes | null
) => {
  if (!digestedSamples.length) return []

  const intervalOptions = new Set<NumericIntervals>()
  let selectedTestCategories: TestCategoriesKey[] = []
  if (
    settings.baselineVariation &&
    settings.carbonCureVariation &&
    orcaVariationType
  )
    selectedTestCategories =
      orcaVariationType === VariationTypes.BASELINE
        ? settings.baselineVariation.selectedTestCategories
        : settings.carbonCureVariation.selectedTestCategories

  const samplesWithoutOutliers = digestedSamples.filter(
    sample => !sample.isOutlier
  )

  for (const sample of samplesWithoutOutliers) {
    if (
      !selectedTestCategories.length ||
      (sample.testCategory !== null &&
        selectedTestCategories.includes(sample.testCategory))
    ) {
      const intervals = Object.keys(sample.strengths)
      intervals.forEach((interval: string) => {
        const numericInterval = Number(interval) as NumericIntervals
        intervalOptions.add(numericInterval)
      })
    }
  }
  const sortedIntervalOptions = Array.from(intervalOptions).sort(
    (a, b) => a - b
  )
  return sortedIntervalOptions
}

export const getVariationOptions = (
  digestedVariations: ICommissionReportVariation[]
) => {
  const variationOptions = []
  for (const variation of digestedVariations) {
    variationOptions.push({
      id: variation.variationId,
      label: variation.variationIdLabel,
    })
  }
  return variationOptions
}

export const getTestResultsPropertyOptions = (isMetric: boolean) => {
  const testResultsPropertyOptions = [
    {
      id: 'cO2Dosage',
      kelownaId: 'CO2Dose',
      label: 'CO₂ Dose.',
      labelWithUnit: `CO₂ Dose. (${
        isMetric ? PropertyUnit.MilliLitrePerM3 : PropertyUnit.OzPerYd3
      })`,
      type: 'freshProperty',
    },
    {
      id: 'slump',
      kelownaId: 'AverageSlump',
      label: 'Avg. Slump',
      labelWithUnit: `Avg. Slump (${isMetric ? 'mm' : 'in'})`,
      type: 'freshProperty',
    },
    {
      id: 'slumpStdDev',
      kelownaId: 'SlumpStandardDeviation',
      label: 'Slump St. Dev.',
      labelWithUnit: `Slump St. Dev (${isMetric ? 'mm' : 'in'})`,
      type: 'freshProperty',
    },
    {
      id: 'airContent',
      kelownaId: 'AverageAir',
      label: 'Avg. Air',
      labelWithUnit: 'Avg. Air (%)',
      type: 'freshProperty',
    },
    {
      id: 'airStdDev',
      kelownaId: 'AirStandardDeviation',
      label: 'Air St. Dev.',
      labelWithUnit: 'Air St. Dev (%)',
      type: 'freshProperty',
    },
    {
      id: 'concreteTemperature',
      kelownaId: 'ConcreteTemperature',
      label: 'Temp.',
      labelWithUnit: `Temp. (${
        isMetric ? PropertyUnit.DegreeCelsius : PropertyUnit.DegreeFahrenheit
      })`,
      type: 'freshProperty',
    },
    {
      id: 'cementEfficiency',
      kelownaId: 'CementEfficiency',
      label: 'Cmt. Eff.',
      labelWithUnit: `Cmt. Eff. (${
        isMetric ? PropertyUnit.MPaM3PerKg : PropertyUnit.PsiYd3PerLb
      })`,
      type: 'freshProperty',
    },
    {
      id: 'cementContent',
      kelownaId: 'AvgCementLoading',
      label: 'Avg. Cement Load.',
      labelWithUnit: `Avg. Cement Load. (${
        isMetric ? PropertyUnit.KgPerM3 : PropertyUnit.LbPerYd3
      })`,
      type: 'freshProperty',
    },
    {
      id: 'unitWeight',
      kelownaId: 'UnitWeight',
      label: 'Unit Weight',
      labelWithUnit: `Unit Weight (${
        isMetric ? PropertyUnit.KgPerM3 : PropertyUnit.LbPerYd3
      })`,
      type: 'freshProperty',
    },
    {
      id: 'sampleCount',
      kelownaId: 'SampleCount',
      label: 'Sample Count',
      labelWithUnit: `Sample Count`,
      type: 'freshProperty',
    },
    {
      id: 'scmLoading',
      kelownaId: 'ScmLoading',
      label: 'Avg. SCM Load.',
      labelWithUnit: `Avg. SCM Load. (${
        isMetric ? PropertyUnit.KgPerM3 : PropertyUnit.LbPerYd3
      })`,
      type: 'freshProperty',
    },
  ]
  return testResultsPropertyOptions
}

export const getRange = (
  min: number,
  max: number,
  property: string,
  isMetric: boolean
) => {
  if (min === null || max === null) return null
  if (property === 'slump') {
    const minRounded = isMetric
      ? Number(min.toFixed(0))
      : Number(ConvertMMtoINCH(min)?.toFixed(0))
    const maxRounded = isMetric
      ? Number(max.toFixed(0))
      : Number(ConvertMMtoINCH(max)?.toFixed(0))
    return `${minRounded} - ${maxRounded}`
  } else if (property === 'air') {
    return `${min.toFixed(0)} - ${max.toFixed(0)}`
  }
  return null
}

/**
 * A sample is considered valid if:
 *   - It is not an outlier.
 *   - It has a non-null and non-undefined value for the specified property.
 *   - The value of the property is a number.
 *   - If no selected test categories, the sample is valid if the above conditions are met.
 *   - If there is selected test categories, the sample has to fall under the selected test categories to be valid.
 *
 * @param sample The sample to be checked.
 * @param property The property of the sample to be evaluated.
 * @param selectedTestCategories The selected test categories to filter the samples.
 * @returns A boolean indicating whether the sample is valid.
 */
export const isValidSample = (
  sample: ICommissionReportSample,
  property: keyof ICommissionReportSample,
  selectedTestCategories: TestCategoriesKey[]
) => {
  return (
    sample[property] !== null &&
    sample[property] !== undefined &&
    typeof sample[property] === 'number' &&
    !sample.isOutlier &&
    (!selectedTestCategories.length ||
      (sample.testCategory !== null &&
        selectedTestCategories.includes(sample.testCategory)))
  )
}

export const getMixDesignDetailLabels = (
  plantType?: string,
  isMetric?: boolean,
  hasScmPercent?: boolean
): IMixSelectionProperty[] => {
  if (plantType === undefined || isMetric === undefined) return []
  const labelObjs: IMixSelectionProperty[] = []
  const fixedLabels1 = [
    {
      id: 'waterCementRatio',
      name: 'W/CM',
    },
    {
      id: 'airContentRange',
      name: 'Air Content Range (%)',
    },
  ]

  const fixedLabels2 = [
    {
      id: 'scmTypes',
      name: 'SCM Type',
    },
    {
      id: 'scmPercent',
      name: 'SCM Content (%)',
    },
    {
      id: 'cementTypes',
      name: 'Cmt. Type',
    },
    {
      id: 'cementContent',
      name: `${hasScmPercent ? 'Cem.+ Load' : 'Cmt. Load'} (${
        isMetric ? PropertyUnit.KgPerM3 : PropertyUnit.LbPerYd3
      })`,
    },
  ]

  labelObjs.push({
    id: 'designStrength',
    name: `${
      plantType === 'Precast' ? 'Release Strength' : 'Design Strength'
    } (${isMetric ? 'MPa' : 'psi'})`,
    isReadOnly: true,
  })
  labelObjs.push(...fixedLabels1)
  labelObjs.push({
    id: 'slumpRange',
    name: `${plantType === 'Precast' ? 'Flow Range' : 'Slump Range'} (${
      isMetric ? 'mm' : 'in'
    })`,
  })
  labelObjs.push(...fixedLabels2)
  return labelObjs
}

export const getViewMixDesignDetailLabels = (
  plantType?: string,
  isMetric?: boolean,
  hasScmPercent?: boolean
): IMixSelectionProperty[] => {
  if (plantType === undefined || isMetric === undefined) return []
  const labelObjs: IMixSelectionProperty[] = [
    {
      id: 'mixDesignId',
      name: 'Mix Design',
    },
    {
      id: 'designStrength',
      name: `${
        plantType === 'Precast' ? 'Release Strength' : 'Design Strength'
      }`,
    },
    {
      id: 'waterCementRatio',
      name: 'W/CM',
    },
    {
      id: 'airContentRange',
      name: 'Air Content Range',
    },
    {
      id: 'slumpRange',
      name: `${plantType === 'Precast' ? 'Flow Range' : 'Slump Range'}`,
    },
    {
      id: 'scmTypes',
      name: 'SCM Type',
    },
    {
      id: 'scmPercent',
      name: 'SCM Content',
    },
    {
      id: 'cementTypes',
      name: 'Cement Type',
    },
    {
      id: 'cementContent',
      name: `${hasScmPercent ? 'Cementitious Content' : 'Cement Load'}`,
    },
  ]
  return labelObjs
}

export const getViewPropertyValueWithUnit = (
  mixSelection: IMixSelection,
  property: string,
  isMetric?: boolean
) => {
  const rawValue = mixSelection[property as keyof IMixSelection]

  if (
    rawValue === null ||
    rawValue === undefined ||
    (typeof rawValue === 'number' && isNaN(rawValue))
  ) {
    return null
  }

  if (typeof rawValue === 'string') {
    return formatStringValue(rawValue, property, isMetric)
  } else if (typeof rawValue === 'number') {
    return formatNumberValue(rawValue, property, isMetric)
  } else if (Array.isArray(rawValue) && rawValue.length) {
    return rawValue.join(',\n')
  }
  return null

  function formatStringValue(
    rawValue: string,
    property: string,
    isMetric?: boolean
  ): string | null {
    switch (property) {
      case 'airContentRange':
        return `${rawValue} %`
      case 'slumpRange':
        return `${rawValue} ${isMetric ? 'mm' : 'in'}`
      default:
        return rawValue
    }
  }

  function formatNumberValue(
    rawValue: number,
    property: string,
    isMetric?: boolean
  ): string {
    switch (property) {
      case 'designStrength':
        return `${rawValue.toFixed(isMetric ? 2 : 0)} ${
          isMetric ? 'MPa' : 'psi'
        }`
      case 'scmPercent':
        return `${rawValue.toFixed(2)}%`
      case 'waterCementRatio':
        return `${rawValue.toFixed(3)}`
      case 'cementContent':
        return `${rawValue.toFixed(0)} ${
          isMetric ? PropertyUnit.KgPerM3 : PropertyUnit.LbPerYd3
        }`
      default:
        return `${rawValue}`
    }
  }
}

export const getPropertyValue = (
  mixSelection: IMixSelection,
  property: string,
  isMetric?: boolean
) => {
  switch (property) {
    case 'designStrength':
      return mixSelection[property]?.toFixed(isMetric ? 2 : 0)
    case 'waterCementRatio':
      return mixSelection[property]?.toFixed(3)
    case 'scmPercent':
      return mixSelection[property]?.toFixed(2)
    case 'cementContent':
      return mixSelection[property]?.toFixed(0)
    default:
      return mixSelection[property as keyof IMixSelection]
  }
}

/**
 * A function to populate the baseline and carboncure fields in the report settings' mix design settings. Includes the mix design id too.
 * @param {number} mixDesignId The mix design id
 * @param {IReportMixSelectionSettings} mixDesignSettings The settings for the selected mix group
 * @param {ICommissionReportVariation} baselineVariation The selected baseline variation for the selected mix group
 * @param {ICommissionReportVariation} carbonCureVariation The selected carboncure variation for the selected mix group
 * @returns
 */
export const populateVariationSettings = (
  mixDesignId: number,
  mixDesignSettings: IReportMixSelectionSettings,
  baselineVariation: ICommissionReportVariation,
  carbonCureVariation: ICommissionReportVariation
) => {
  if (!mixDesignSettings) return
  let baselineSelectedTestCategories: TestCategoriesKey[] = []
  let carbonCureSelectedTestCategories: TestCategoriesKey[] = []
  if (
    mixDesignSettings.baselineVariation?.selectedTestCategories.length === 0 ||
    !mixDesignSettings.baselineVariation
  ) {
    for (const option of baselineVariation.testCategoryOptions) {
      baselineSelectedTestCategories.push(option.id as TestCategoriesKey)
    }
  } else if (
    mixDesignSettings.baselineVariation?.selectedTestCategories.length ===
    baselineVariation.testCategoryOptions.length - 1
  ) {
    baselineSelectedTestCategories = [
      ...mixDesignSettings.baselineVariation.selectedTestCategories,
    ]
    baselineSelectedTestCategories.push('all' as TestCategoriesKey)
  } else {
    baselineSelectedTestCategories = [
      ...mixDesignSettings.baselineVariation.selectedTestCategories,
    ]
  }

  if (
    mixDesignSettings.carbonCureVariation?.selectedTestCategories.length ===
      0 ||
    !mixDesignSettings.carbonCureVariation
  ) {
    for (const option of carbonCureVariation.testCategoryOptions) {
      carbonCureSelectedTestCategories.push(option.id as TestCategoriesKey)
    }
  } else if (
    mixDesignSettings.carbonCureVariation?.selectedTestCategories.length ===
    carbonCureVariation.testCategoryOptions.length - 1
  ) {
    carbonCureSelectedTestCategories = [
      ...mixDesignSettings.carbonCureVariation.selectedTestCategories,
    ]
    carbonCureSelectedTestCategories.push('all' as TestCategoriesKey)
  } else {
    carbonCureSelectedTestCategories = [
      ...mixDesignSettings.carbonCureVariation.selectedTestCategories,
    ]
  }

  mixDesignSettings.mixDesignId = mixDesignId
  mixDesignSettings.baselineVariation = {
    selectedTestCategories: baselineSelectedTestCategories,
    cO2Dosage: baselineVariation.cO2Dosage,
    cO2DosageUnit: baselineVariation.cO2DosageUnit,
    cementReductionPercent: baselineVariation.cementReduction,
  }

  mixDesignSettings.carbonCureVariation = {
    selectedTestCategories: carbonCureSelectedTestCategories,
    cO2Dosage: carbonCureVariation.cO2Dosage,
    cO2DosageUnit: carbonCureVariation.cO2DosageUnit,
    cementReductionPercent: carbonCureVariation.cementReduction,
  }
}

/**
 * A function to populate the selected intervals for the histogram of a mix.
 * @param {ICommissionReportFrequencyGraphSettings} frequencyGraphSettings The settings for the frequency graph
 * @param {NumericIntervals[]} intervalOptions The interval options for the selected mix
 * @param {NumericIntervals | null} specifiedMaturityAge The specified maturity age of the mix group
 */
export const populateHistogramIntervals = (
  frequencyGraphSettings: ICommissionReportFrequencyGraphSettings,
  intervalOptions: NumericIntervals[],
  specifiedMaturityAge: NumericIntervals | null
) => {
  let selectedInterval: NumericIntervals = 672
  const currentSelection = frequencyGraphSettings.selectedInterval

  if (currentSelection) {
    const isCurrentSelectionValid = intervalOptions.includes(currentSelection)
    if (isCurrentSelectionValid) {
      selectedInterval = currentSelection
    } else if (
      specifiedMaturityAge !== null &&
      intervalOptions.includes(specifiedMaturityAge)
    ) {
      selectedInterval = specifiedMaturityAge
    } else if (intervalOptions.length > 0) {
      selectedInterval = intervalOptions[intervalOptions.length - 1]
    }
  } else if (
    specifiedMaturityAge !== null &&
    intervalOptions.includes(specifiedMaturityAge)
  ) {
    selectedInterval = specifiedMaturityAge
  } else if (intervalOptions.length > 0) {
    selectedInterval = intervalOptions[intervalOptions.length - 1]
  }
  frequencyGraphSettings.selectedInterval = selectedInterval
  //Update showFailureZone settings according to the selected interval
  frequencyGraphSettings.showFailureZone =
    selectedInterval === specifiedMaturityAge
      ? frequencyGraphSettings.showFailureZone
      : false
}

/**
 * A function to populate the selected intervals for the bar graph of a mix.
 * @param {ICommissionReportAvgStrengthGraphSettings} barGraphSettings The settings for the bar graph
 * @param {NumericIntervals[]} intervalOptions The interval options for the selected mix
 * @param {NumericIntervals | null} specifiedMaturityAge The specified maturity age of the mix group
 */
export const populateBarGraphIntervals = (
  barGraphSettings: ICommissionReportAvgStrengthGraphSettings,
  intervalOptions: NumericIntervals[],
  specifiedMaturityAge: NumericIntervals | null
) => {
  let selectedIntervals: NumericIntervals[] = []
  const currentSelections = barGraphSettings.selectedInterval
  if (currentSelections.length > 0) {
    const validCurrentSelections = currentSelections.filter(selectedInterval =>
      intervalOptions.includes(selectedInterval)
    )
    if (validCurrentSelections.length > 0) {
      selectedIntervals = [...validCurrentSelections]
    } else if (
      specifiedMaturityAge !== null &&
      intervalOptions.includes(specifiedMaturityAge)
    ) {
      selectedIntervals = [specifiedMaturityAge]
    } else if (intervalOptions.length > 0) {
      selectedIntervals = [intervalOptions[intervalOptions.length - 1]]
    }
  } else if (
    specifiedMaturityAge !== null &&
    intervalOptions.includes(specifiedMaturityAge)
  ) {
    selectedIntervals = [specifiedMaturityAge]
  } else if (intervalOptions.length > 0) {
    selectedIntervals = [intervalOptions[intervalOptions.length - 1]]
  }
  barGraphSettings.selectedInterval = selectedIntervals
}

/**
 * A function that sets up the report settings structure when a selected mix group is being changed or added
 * @param {ICommissionReportSettings} prevReportSettings The previous report settings that needs to be updated
 * @param {ICommissionReportSettings} newReportSettings The report settings that will be updated
 * @param {number} rowIndex The index of the selected mix design
 * @param {number} mixDesignId The mix design id of the mix design
 */
export const reportSettingsMixGroupChangeHelper = (
  prevReportSettings: ICommissionReportSettings,
  newReportSettings: ICommissionReportSettings,
  rowIndex: number,
  mixDesignId: number
) => {
  if (rowIndex + 1 > prevReportSettings.mixDesignSettings.length)
    newReportSettings.mixDesignSettings.push({
      mixDesignId: mixDesignId,
      mixDesignPropertiesIncludedOnCustomerReport: {
        designStrength: true,
        waterCementRatio: true,
        airContentRange: true,
        slumpRange: true,
        scmTypes: false,
        scmPercent: true,
        cementTypes: true,
        cementContent: true,
      },
      testResults: {
        selectedProperties: [...defaultSelectedTestResultsProperties],
        selectedStrengthHours: [],
        selectedStrengthStDevHours: [],
        showDifference: true,
      },
      frequencyGraph: {
        isSelected: true,
        selectedInterval: 0 as NumericIntervals,
        showFailureZone: true,
        showHistogram: true,
        showBellCurve: true,
      },
      avgStrengthGraph: {
        isSelected: true,
        selectedInterval: [],
        showDesignStrength: true,
      },
      outlierSettings: {
        show: true,
        showReason: true,
      },
    })
  else {
    // Remove the baseline and carboncure variation settings so we know to make an api request for this mix design
    newReportSettings.mixDesignSettings[rowIndex].mixDesignId = mixDesignId
    delete newReportSettings.mixDesignSettings[rowIndex].baselineVariation
    delete newReportSettings.mixDesignSettings[rowIndex].carbonCureVariation
    // Reset selected strength hours / stdev hours when mix group is changed within the same report
    newReportSettings.mixDesignSettings[
      rowIndex
    ].testResults.selectedStrengthHours = []
    newReportSettings.mixDesignSettings[
      rowIndex
    ].testResults.selectedStrengthStDevHours = []
  }
}

/**
 * A function to change the report settings' mix design settings for either the baseline or carboncure variation depending on the variation type.
 * @param {IMixSelection} newMix A digested mix design
 * @param {ICommissionReportSettings} reportSettings Digested report settings
 * @param {number} index The index of the mix design in the mix design settings
 * @param {ICommissionReportVariation} value The variation option that is being selected
 * @param {VariationTypes} variationType The variation type of the variation that's being changed
 * @returns An object containing the updated mix design settings and new selected variation
 */
export const handleVariationChangeHelper = (
  newMix: IMixSelection,
  reportSettings: ICommissionReportSettings,
  index: number,
  value: ICommissionReportVariationOption,
  variationType: VariationTypes
) => {
  const prevSelectedVariation = newMix.digestedVariations.find(
    (variation: ICommissionReportVariation) => {
      if (variationType === VariationTypes.BASELINE)
        return variation.orcaVariationType === VariationTypes.BASELINE
      else return variation.orcaVariationType === VariationTypes.OPTIMIZED
    }
  )
  const newSelectedVariation = newMix.digestedVariations.find(
    (variation: ICommissionReportVariation) =>
      variation.variationId === value.id
  )

  if (!prevSelectedVariation || !newSelectedVariation) return
  prevSelectedVariation.orcaVariationType = null
  newSelectedVariation.orcaVariationType =
    variationType === VariationTypes.BASELINE
      ? VariationTypes.BASELINE
      : VariationTypes.OPTIMIZED
  const mixDesignSettings = reportSettings.mixDesignSettings[index]
  mixDesignSettings.mixDesignId = newMix.mixDesignId
  if (variationType === VariationTypes.BASELINE) {
    mixDesignSettings.baselineVariation = {
      selectedTestCategories: newSelectedVariation.testCategoryOptions.map(
        (testCategoryOption: TestCategoryOption) => testCategoryOption.id
      ),
      cO2Dosage: newSelectedVariation.cO2Dosage,
      cO2DosageUnit: newSelectedVariation.cO2DosageUnit,
      cementReductionPercent: newSelectedVariation.cementReduction,
    }
  } else {
    mixDesignSettings.carbonCureVariation = {
      selectedTestCategories: newSelectedVariation.testCategoryOptions.map(
        (testCategoryOption: TestCategoryOption) => testCategoryOption.id
      ),
      cO2Dosage: newSelectedVariation.cO2Dosage,
      cO2DosageUnit: newSelectedVariation.cO2DosageUnit,
      cementReductionPercent: newSelectedVariation.cementReduction,
    }
  }

  // Reset selected strength hours / stdev hours once after updating variations
  mixDesignSettings.testResults.selectedStrengthHours = []
  mixDesignSettings.testResults.selectedStrengthStDevHours = []
  return { mixDesignSettings, newSelectedVariation }
}

/**
 * A function that populates a test categories array with test category keys.
 * @param {TestCategoriesKey[]} mixSelectionTestCategories The test category keys array
 * @param {TestCategoryOptions[]} values The test category options that will be used to populate the array
 * @param {boolean} skipAllOption Whether or not to skip the 'all' option when populating the test category keys array
 */
export const populateSelectedTestCategoriesArray = (
  mixSelectionTestCategories: TestCategoriesKey[],
  values: TestCategoryOption[],
  skipAllOption: boolean
) => {
  for (const value of values) {
    if (skipAllOption && value.id === 'all') continue
    mixSelectionTestCategories.push(value.id as TestCategoriesKey)
  }
}

/**
 * A function that updates the selected test categories for the baseline or carboncure variation depending on the variation type
 * @param {ICommissionReportVariation} variation The variation for which the selected test categories are changing
 * @param {ICommissionReportSettings} reportSettings The digested report settings
 * @param {TestCategoryOption[]} values The array of the selected test categories
 * @param {number} index The index of the mix design in the mix design settings
 * @param {VariationTypes} variationType The variation type of the variation that's being changed
 * @returns
 */
export const updateSelectedTestCategories = (
  variation: ICommissionReportVariation,
  reportSettings: ICommissionReportSettings,
  values: TestCategoryOption[],
  index: number,
  variationType: VariationTypes
) => {
  if (
    reportSettings.mixDesignSettings[index].baselineVariation === undefined ||
    reportSettings?.mixDesignSettings[index].carbonCureVariation === undefined
  )
    return
  const key =
    variationType === VariationTypes.BASELINE
      ? 'baselineVariation'
      : 'carbonCureVariation'
  const allOptions = variation.testCategoryOptions
  const previousTestCategories =
    reportSettings.mixDesignSettings[index][key]?.selectedTestCategories
  if (previousTestCategories === undefined) return
  const wasAllPreviouslySelected = previousTestCategories.includes('all')
  const isAllIncluded = values.some(
    selectedOption => selectedOption.id === 'all'
  )
  let newTestCategories: TestCategoriesKey[] = []

  if (wasAllPreviouslySelected && isAllIncluded && values.length > 1) {
    populateSelectedTestCategoriesArray(newTestCategories, values, true)
  } else if (
    values.length === 0 ||
    (wasAllPreviouslySelected && isAllIncluded && values.length === 1) ||
    (!wasAllPreviouslySelected &&
      (isAllIncluded ||
        (!isAllIncluded && allOptions.length - values.length === 1)))
  ) {
    populateSelectedTestCategoriesArray(newTestCategories, allOptions, false)
  } else if (!wasAllPreviouslySelected && !isAllIncluded) {
    populateSelectedTestCategoriesArray(newTestCategories, values, false)
  }

  reportSettings.mixDesignSettings[index][
    key
  ].selectedTestCategories = newTestCategories
}

export const filterSamplesByTestCategories = (
  variation: ICommissionReportVariation,
  variationType: VariationTypes,
  reportSettings: ICommissionReportSettings | undefined
) => {
  if (!variation?.samples?.length || !reportSettings) return
  const mixDesignId = variation.mixDesignId
  const key =
    variationType === VariationTypes.BASELINE
      ? 'baselineVariation'
      : 'carbonCureVariation'
  const mixDesignSettings = reportSettings.mixDesignSettings.find(
    settings => settings.mixDesignId === mixDesignId
  )
  if (!mixDesignSettings) return
  // Filter the samples according to selectedTestCategories
  const filteredSamples = variation.samples.filter(
    sample =>
      !sample.isOutlier &&
      sample.testCategory !== null &&
      mixDesignSettings[key]?.selectedTestCategories.includes(
        sample.testCategory
      )
  )
  // Update object with the filtered samples
  variation.filteredSamples = filteredSamples
}

// Get sample ids for view batch data url
export const getFilteredSampleIds = (variation: ICommissionReportVariation) => {
  return (
    variation.filteredSamples?.map(
      sample => 'batchTestSampleIds=' + sample.batchId
    ) ?? []
  )
}

// Get baseline or carboncure variation with filtered samples according selected test categories
export const getVariationWithFilteredSamples = (
  variations: ICommissionReportVariation[],
  variationType: VariationTypes,
  reportSettings: ICommissionReportSettings
) => {
  const variation = findVariationByType(variations, variationType)!
  filterSamplesByTestCategories(variation, variationType, reportSettings)
  return variation
}

export const handleVariationSwapHelper = (
  mixSelection: IMixSelection,
  reportSettings: ICommissionReportSettings,
  index: number
) => {
  const prevBaseline = mixSelection.digestedVariations.find(
    (variation: ICommissionReportVariation) =>
      variation.orcaVariationType === VariationTypes.BASELINE
  )
  const prevCarbonCure = mixSelection.digestedVariations.find(
    (variation: ICommissionReportVariation) =>
      variation.orcaVariationType === VariationTypes.OPTIMIZED
  )
  if (!prevBaseline || !prevCarbonCure) return
  prevBaseline.orcaVariationType = VariationTypes.OPTIMIZED
  prevCarbonCure.orcaVariationType = VariationTypes.BASELINE
  const mixDesignSettings = reportSettings.mixDesignSettings[index]
  mixDesignSettings.mixDesignId = mixSelection.mixDesignId

  mixDesignSettings.baselineVariation = {
    selectedTestCategories: prevCarbonCure.testCategoryOptions.map(
      (testCategoryOption: TestCategoryOption) => testCategoryOption.id
    ),
    cO2Dosage: prevCarbonCure.cO2Dosage,
    cO2DosageUnit: prevCarbonCure.cO2DosageUnit,
    cementReductionPercent: prevCarbonCure.cementReduction,
  }

  mixDesignSettings.carbonCureVariation = {
    selectedTestCategories: prevBaseline.testCategoryOptions.map(
      (testCategoryOption: TestCategoryOption) => testCategoryOption.id
    ),
    cO2Dosage: prevBaseline.cO2Dosage,
    cO2DosageUnit: prevBaseline.cO2DosageUnit,
    cementReductionPercent: prevBaseline.cementReduction,
  }
  // Reset selected strength hours / stdev hours when variation is swapped
  mixDesignSettings.testResults.selectedStrengthHours = []
  mixDesignSettings.testResults.selectedStrengthStDevHours = []

  return {
    newBaselineVariation: prevCarbonCure,
    newCarbonCureVariation: prevBaseline,
    mixDesignSettings: mixDesignSettings,
  }
}

export const getSampleCount = (
  variation: ICommissionReportVariation,
  specifiedAge: number | null,
  mixDesignSettings: IReportMixSelectionSettings[]
) => {
  if (
    !variation?.samples ||
    !Array.isArray(variation.samples) ||
    specifiedAge == null ||
    !mixDesignSettings ||
    !Array.isArray(mixDesignSettings)
  ) {
    return 0
  }

  // Find the matching mix design settings
  const mixDesign = mixDesignSettings.find(
    settings => settings.mixDesignId === variation.mixDesignId
  )

  if (!mixDesign) {
    return 0
  }

  // Determine which variation settings to use based on orcaVariationType
  const variationSettings =
    variation?.orcaVariationType === 'Baseline'
      ? mixDesign.baselineVariation
      : mixDesign.carbonCureVariation

  const selectedTestCategories = variationSettings?.selectedTestCategories

  return variation?.samples.reduce((count, sample) => {
    // Check if sample meets all criteria:
    // 1. Has strength data for specified age
    // 2. Is not an outlier
    // 3. Has a test category that's in the allowed categories
    if (
      sample.strengths &&
      specifiedAge in sample.strengths &&
      !sample.isOutlier &&
      sample.testCategory &&
      selectedTestCategories?.includes(sample.testCategory)
    ) {
      return count + 1
    }
    return count
  }, 0)
}

export const getTestResultsPropertyValue = (
  variation: ICommissionReportVariation,
  property: ITestResultsPropertyOption,
  isMetric: boolean,
  specifiedMaturityAge: number | null,
  mixDesignSettings: IReportMixSelectionSettings[],
  isDifferentCO2DosageUnit?: boolean
) => {
  if (!variation) return

  const getValueWithPrecision = (value: number | null, precision: number) => {
    if (value === null || value === undefined) return '-'
    return value.toFixed(precision ?? 2)
  }

  const cO2DosageUnitLabel = isDifferentCO2DosageUnit
    ? getCO2DosageUnitLabel(variation.cO2DosageUnit, isMetric, true)
    : ''

  const cO2Dosage = getCO2Dosage(
    variation[property.id] as number,
    variation.cO2DosageUnit,
    isMetric
  )

  switch (property.type) {
    case 'strength':
      return getValueWithPrecision(
        variation.strengths[property.kelownaId]?.strength,
        isMetric ? 2 : 0
      )
    case 'standardDeviation':
      return getValueWithPrecision(
        variation.strengths[property.kelownaId]?.stDev,
        isMetric ? 2 : 0
      )
    case 'freshProperty':
      switch (property.id) {
        case 'cO2Dosage':
          return (
            getValueWithPrecision(
              cO2Dosage,
              getCO2DosagePrecision(variation.cO2DosageUnit, isMetric)
            ) + ` ${cO2DosageUnitLabel}`
          )
        case 'slump':
        case 'slumpStdDev':
          return getValueWithPrecision(variation[property.id], isMetric ? 0 : 1)
        case 'airContent':
        case 'airStdDev':
          return getValueWithPrecision(variation[property.id], 1)
        case 'concreteTemperature':
          return getValueWithPrecision(variation[property.id], 2)
        case 'cementContent':
          return getValueWithPrecision(variation[property.id], 0)
        case 'scmLoading':
          return getValueWithPrecision(variation[property.id], 0)
        case 'sampleCount':
          return getSampleCount(
            variation,
            specifiedMaturityAge,
            mixDesignSettings
          )
        default:
          return getValueWithPrecision(
            variation[property.id as keyof ICommissionReportVariation],
            2
          )
      }
    default:
      return '-'
  }
}

export const getTestResultsPropertyPercentage = (
  baselineVariation: ICommissionReportVariation,
  carbonCureVariation: ICommissionReportVariation,
  property: ITestResultsPropertyOption,
  isDifferentCO2DosageUnit?: boolean
) => {
  const getPropertyValues = (
    variation: ICommissionReportVariation,
    property: ITestResultsPropertyOption
  ) => {
    const value = variation[property.id]
    if (property.type === 'strength') {
      return variation.strengths[property.kelownaId]?.strength
    } else if (property.type === 'standardDeviation') {
      return variation.strengths[property.kelownaId]?.stDev
    } else {
      return value
    }
  }

  const baselineValue = getPropertyValues(baselineVariation, property)
  const carbonCureValue = getPropertyValues(carbonCureVariation, property)

  if (
    !baselineValue ||
    carbonCureValue === null ||
    carbonCureValue === undefined ||
    (property.id === 'cO2Dosage' && isDifferentCO2DosageUnit)
  )
    return '-'

  const percentage = ((carbonCureValue / baselineValue) * 100).toFixed(2)

  return `${percentage}%`
}

export const getTestResultsIntervalOptions = (
  reportSettings: ICommissionReportSettings,
  tabValue: number
): NumericIntervals[] => {
  const { frequencyGraph, avgStrengthGraph } =
    reportSettings?.mixDesignSettings?.[tabValue] || {}
  const intervalOptions: NumericIntervals[] = []

  if (frequencyGraph?.isSelected) {
    intervalOptions.push(frequencyGraph.selectedInterval)
  }
  if (avgStrengthGraph?.isSelected) {
    intervalOptions.push(...avgStrengthGraph.selectedInterval)
  }
  const sortedIntervalOptions = Array.from(new Set(intervalOptions)).sort(
    (a, b) => a - b
  )
  return sortedIntervalOptions
}

export const getSelectedTestResultsInterval = (
  reportSettings: ICommissionReportSettings,
  mixSelection: IMixSelection,
  tabValue: number
): NumericIntervals => {
  const { frequencyGraph, avgStrengthGraph } =
    reportSettings?.mixDesignSettings?.[tabValue] || {}
  let selectedInterval = mixSelection.strengthIntervalHours

  if (frequencyGraph?.isSelected && avgStrengthGraph?.isSelected) {
    const selectedGraphIntervals = Array.from(
      new Set([
        frequencyGraph.selectedInterval,
        ...avgStrengthGraph.selectedInterval,
      ])
    ).sort((a, b) => a - b)
    //use the specified age as selected, if it is not in the selectedGraphIntervals, then use the oldest age
    selectedInterval = selectedGraphIntervals.includes(
      mixSelection.strengthIntervalHours
    )
      ? mixSelection.strengthIntervalHours
      : selectedGraphIntervals[selectedGraphIntervals.length - 1]
  } else if (frequencyGraph?.isSelected) {
    //use the selected age as selected if only frequencyGraph is selected
    selectedInterval = frequencyGraph.selectedInterval
  } else if (avgStrengthGraph?.isSelected) {
    const selectedGraphIntervals = [...avgStrengthGraph.selectedInterval].sort(
      (a, b) => a - b
    )
    //use the specified age as selected, if it is not in the selectedGraphIntervals, then use the oldest age
    selectedInterval = selectedGraphIntervals.includes(
      mixSelection.strengthIntervalHours
    )
      ? mixSelection.strengthIntervalHours
      : selectedGraphIntervals[selectedGraphIntervals.length - 1]
  }

  return selectedInterval as NumericIntervals
}

export const getAllSelectedTestResultsProperties = (
  reportSettings: ICommissionReportSettings,
  tabValue: number
) => {
  const allSelectedProperties: string[] = [
    ...(reportSettings?.mixDesignSettings?.[tabValue]?.testResults
      ?.selectedProperties ?? []),
    // add selected strength hours, with 'StrengthHours' suffix for differentiation
    ...(reportSettings?.mixDesignSettings?.[
      tabValue
    ]?.testResults?.selectedStrengthHours?.map(
      (selected: number) => `${selected}StrengthHours`
    ) ?? []),
    // add selected strength st dev hours, with 'StrengthHoursStDev' suffix for differentiation
    ...(reportSettings?.mixDesignSettings?.[
      tabValue
    ]?.testResults?.selectedStrengthStDevHours?.map(
      (selected: number) => `${selected}StrengthHoursStDev`
    ) ?? []),
  ]
  return allSelectedProperties
}

/**
 * A function to remove the 3rd selected interval from the bar graph settings if both graphs are about to be visible
 * @param changedGraphType the type of graph whose visibility is toggling
 * @param avgStrengthGraphSelected A boolean indicating whether the avg strength graph is currently visible
 * @param frequencyGraphSelected A boolean indicating whether the frequenty graph is currently visible
 * @param mixGroupInterval The specified maturity age of the mix group
 * @param reportSettings digested report settings
 * @param tabValue the index value of the relevant mix group
 */
export const removeIntervalFromAvgStrengthGraph = (
  changedGraphType: string,
  avgStrengthGraphSelected: boolean,
  frequencyGraphSelected: boolean,
  mixGroupInterval: NumericIntervals | null,
  reportSettings: ICommissionReportSettings,
  tabValue: number
) => {
  if (
    (changedGraphType === 'frequencyGraph' &&
      !frequencyGraphSelected &&
      avgStrengthGraphSelected &&
      reportSettings.mixDesignSettings[tabValue].avgStrengthGraph
        .selectedInterval.length === 3) ||
    (changedGraphType === 'avgStrengthGraph' &&
      frequencyGraphSelected &&
      !avgStrengthGraphSelected &&
      reportSettings.mixDesignSettings[tabValue].avgStrengthGraph
        .selectedInterval.length === 3)
  ) {
    const sortedIntervals = [
      ...reportSettings.mixDesignSettings[tabValue].avgStrengthGraph
        .selectedInterval,
    ].sort((a: NumericIntervals, b: NumericIntervals) => a - b)
    let intervalToRemove: NumericIntervals
    for (const interval of sortedIntervals) {
      if (interval !== mixGroupInterval) {
        intervalToRemove = interval
        break
      }
    }
    const newIntervals = reportSettings.mixDesignSettings[
      tabValue
    ].avgStrengthGraph.selectedInterval.filter(
      (interval: NumericIntervals) => interval !== intervalToRemove
    )
    reportSettings.mixDesignSettings[
      tabValue
    ].avgStrengthGraph.selectedInterval = newIntervals
  }
}

/**
 * Filters selectedStrengthStDevHours and selectedStrengthHours based on the graph type and its selection status.
 *
 * @param {string} changedGraphType - The type of graph that was clicked ('frequencyGraph' or 'avgStrengthGraph').
 * @param {ICommissionReportSettings} reportSettings - The original report settings.
 * @param {ICommissionReportSettings} newSettings - The updated report settings.
 * @param {number} tabValue - the index value of the relevant mix group
 */
export const filterSelectedStrengthAndStDevHours = (
  changedGraphType: string,
  reportSettings: ICommissionReportSettings,
  newSettings: ICommissionReportSettings,
  tabValue: number
) => {
  // Destructure report settings to get current selected hours and graph intervals
  const {
    testResults: { selectedStrengthStDevHours, selectedStrengthHours },
    avgStrengthGraph: { selectedInterval: avgStrengthGraphSelectedInterval },
    frequencyGraph: { selectedInterval: frequencyGraphSelectedInterval },
  } = reportSettings.mixDesignSettings[tabValue]

  // Destructure new settings to get graph type and its selection status
  const {
    testResults: newTestResults,
    avgStrengthGraph: { isSelected: isAvgStrengthGraphSelected },
    frequencyGraph: { isSelected: isFrequencyGraphSelected },
  } = newSettings.mixDesignSettings[tabValue]

  // Helper function to filter selected hours based on selected graph intervals
  const filterHours = (
    currentSelectedHours: NumericIntervals[],
    graphIntervals: NumericIntervals[]
  ) =>
    currentSelectedHours?.filter(interval => graphIntervals.includes(interval))

  /**
   * If the frequency graph is deselected, filter selected strength / st dev to only include the selected avg strength graph intervals.
   * Otherwise, if the avg strength graph is deselected, filter selected strength / st dev to only include the selected frequency graph interval.
   */

  let condition: NumericIntervals[] = []
  if (changedGraphType === 'frequencyGraph' && !isFrequencyGraphSelected) {
    condition = avgStrengthGraphSelectedInterval
  } else if (
    changedGraphType === 'avgStrengthGraph' &&
    !isAvgStrengthGraphSelected
  ) {
    condition = [frequencyGraphSelectedInterval]
  }

  // Apply the condition to both selectedStrengthStDevHours and selectedStrengthHours
  if (condition.length > 0) {
    newTestResults.selectedStrengthStDevHours = filterHours(
      selectedStrengthStDevHours,
      condition
    )
    newTestResults.selectedStrengthHours = filterHours(
      selectedStrengthHours,
      condition
    )
  }
}

export const getReportMixCodesString = (
  mixSelection: IMixSelection[]
): string => {
  let mixCodeArray: string[] = []

  mixSelection.forEach(mix => {
    if (!mixCodeArray.includes(mix.mixCode)) {
      mixCodeArray.push(mix.mixCode)
    }
  })

  return mixCodeArray.join(', ')
}

export const generateSingleSummary = (data: SummarySingleData): string => {
  const {
    corporationName,
    locationName,
    mixCode,
    designStrength,
    strengthUnit,
    carboncureCemAdjustment,
    carboncureCO2Dosage,
    carboncureCO2DosageUnit,
    baselineStrength,
    carboncureStrength,
    specifiedMaturityAge,
  } = data

  const unitString =
    carboncureCO2DosageUnit === PropertyUnit.MilliLitrePerM3 ||
    carboncureCO2DosageUnit === PropertyUnit.OzPerYd3
      ? ` ${carboncureCO2DosageUnit}`
      : carboncureCO2DosageUnit

  return `Mix design ${mixCode}, ${designStrength} ${strengthUnit}, was tested with use of CarbonCure \
and a cementitious adjustment at ${corporationName}'s ${locationName} location. Upon review of production data and mix portfolio provided by \
${corporationName}, this mix has been chosen as a high volume mix. Under the testing, the mix was produced with the use of CarbonCure \
(Treated) and without use of CarbonCure (Baseline). The mix, when Treated, was produced with a ${carboncureCemAdjustment}% \
cementitious adjustment to Baseline with a ${carboncureCO2Dosage}${unitString} CO2 dosage. The testing was focused on the addition of CO2 with the aim to \
increase the cement efficiency to allow for an adjustment in cementitious materials while maintaining the compressive strength of the mix. \
In this trial the Baseline mix had an average strength of ${baselineStrength} ${strengthUnit} at ${getIntervalString(
    specifiedMaturityAge
  )}. In comparison, \
the CarbonCure Treated mix had an average strength of ${carboncureStrength} ${strengthUnit} at the same age with a cementitious adjustment of \
${carboncureCemAdjustment}% from Baseline.`
}

export const generateMultipleSummary = (data: SummaryMultipleData): string => {
  const { customerName, locationName, mixAmount, mixCodes } = data

  return `CarbonCure and ${customerName} staff completed testing at their ${locationName} plant, ${mixAmount} mixes were tested (${mixCodes}). \
Upon review of production data and mix portfolio provided by ${customerName}, these mixes have been chosen as high volume mixes. Under the testing, the mixes were \
produced with the use of CarbonCure (Treated) and without use of CarbonCure (Baseline). The testing was focused on the addition of CO2 with the \
aim to increase the cement efficiency to allow for an adjustment in cementitious materials while maintaining the compressive strength of these mixes. For specific \
results of each mix code's trial please see the test results section below.`
}

export const generateConclusion = (
  corporationName: string,
  tssSpecialist: string
) => {
  return `The results of this testing may be used to support your decisions to implement a cement adjustment alongside the use of CarbonCure in concrete \
production. Both the Target and Minimum ROI contemplated under your agreement are based on the assumption of a consistent cement adjustment, CO2 dosage, and use across \
production. Fresh and hardened concrete properties should be monitored in line with your standard testing regime.\n\n\
If assistance is needed to continue implementing CarbonCure across ${corporationName}'s mix portfolio please reach out to ${tssSpecialist}. CarbonCure would like \
to thank ${corporationName} and staff for their assistance in the organization and execution of the commissioning process!`
}

/**
 * Determine if a sample is valid to include in the sufficient sample count for a variation
 * @param {ICommissionReportSample} sample a sample of a variation
 * @param {TestCategoriesKey[]} selectedTestCategories the selected test categories for a variation
 * @param {string} bannerType there are 2 types of insufficient sample banners. default and interval.
 * @param {NumericIntervals} interval the specified maturity age of the mix group
 * @returns {boolean} return true if the sample is valid and the count should be incremented. false if not.
 */
export const shouldIncrementSufficientSampleCount = (
  sample: ICommissionReportSample,
  selectedTestCategories: TestCategoriesKey[],
  bannerType: insufficientSamplesBannerType,
  interval?: NumericIntervals
) => {
  let isValid =
    !sample.isOutlier &&
    selectedTestCategories.length !== 0 &&
    sample.testCategory !== null &&
    selectedTestCategories.includes(sample.testCategory)

  if (bannerType === 'default' || isValid === false) return isValid
  if (interval === undefined) return true
  if (sample.strengths[interval] === undefined) isValid = false
  return isValid
}

/**
 * Determine the number of succifient samples for each variation for both the default and maturity age insufficient sample banners.
 * @param {ICommissionReportSample[]} baselineSamples the samples belonging to the baseline variation
 * @param {ICommissionReportSample[]} carbonCureSamples the samples beloning to the carboncure variation
 * @param {TestCategoriesKey[]} baselineSelectedTestCategories the selected test categories for the baseline variation
 * @param {TestCategoriesKey[]} selectedTestCategories the selected test categories for the carboncure variation
 * @param {NumericIntervals | null} maturityAge
 * @returns object containing the number of sufficient samples for each variation of each banner type
 */
export const getSufficientSampleCounts = (
  baselineSamples: ICommissionReportSample[],
  carbonCureSamples: ICommissionReportSample[],
  baselineSelectedTestCategories: TestCategoriesKey[],
  carbonCureSelectedTestCategories: TestCategoriesKey[],
  maturityAge: NumericIntervals | null
) => {
  let validBaselineSampleCount = 0
  let validCarbonCureSampleCount = 0
  let validBaselineSamplesWithMaturityAge = 0
  let validCarbonCureSamplesWithMaturityAge = 0

  for (const sample of baselineSamples) {
    if (
      shouldIncrementSufficientSampleCount(
        sample,
        baselineSelectedTestCategories,
        'default'
      )
    )
      validBaselineSampleCount++
    if (
      maturityAge &&
      shouldIncrementSufficientSampleCount(
        sample,
        baselineSelectedTestCategories,
        'interval',
        maturityAge
      )
    )
      validBaselineSamplesWithMaturityAge++
  }

  for (const sample of carbonCureSamples) {
    if (
      shouldIncrementSufficientSampleCount(
        sample,
        carbonCureSelectedTestCategories,
        'default'
      )
    )
      validCarbonCureSampleCount++
    if (
      maturityAge &&
      shouldIncrementSufficientSampleCount(
        sample,
        carbonCureSelectedTestCategories,
        'interval',
        maturityAge
      )
    )
      validCarbonCureSamplesWithMaturityAge++
  }

  return {
    validBaselineSampleCount,
    validCarbonCureSampleCount,
    validBaselineSamplesWithMaturityAge,
    validCarbonCureSamplesWithMaturityAge,
  }
}

export interface IGetDataForVariationsWithInsufficientSamplesParams {
  errorData: IInsufficientVariationSamples[]
  maturityAgeErrorData: IInsufficientMaturityAgeSamples
  validBaselineSampleCount: number
  validCarbonCureSampleCount: number
  validBaselineSamplesWithMaturityAge: number
  validCarbonCureSamplesWithMaturityAge: number
  baselineVariation: ICommissionReportVariation
  carbonCureVariation: ICommissionReportVariation
  maturityAge: NumericIntervals | null
}

/**
 * Get error data for default and interval insufficient sample banners
 * @param {IGetDataForVariationsWithInsufficientSamplesParams} params
 * @returns object containing the erro data for each insufficient samples banner
 */
export const getDataForVariationsWithInsufficientSamples = (
  params: IGetDataForVariationsWithInsufficientSamplesParams
) => {
  const {
    errorData,
    maturityAgeErrorData,
    validBaselineSampleCount,
    validCarbonCureSampleCount,
    validBaselineSamplesWithMaturityAge,
    validCarbonCureSamplesWithMaturityAge,
    baselineVariation,
    carbonCureVariation,
    maturityAge,
  } = params

  if (validBaselineSampleCount < 3) {
    errorData.push({
      id: baselineVariation.variationId,
      label: baselineVariation.variationIdLabel,
    })
  }
  if (validCarbonCureSampleCount < 3) {
    errorData.push({
      id: carbonCureVariation.variationId,
      label: carbonCureVariation.variationIdLabel,
    })
  }

  if (maturityAge && validBaselineSamplesWithMaturityAge < 3) {
    maturityAgeErrorData[maturityAge] =
      maturityAgeErrorData[maturityAge] !== undefined
        ? [
            ...maturityAgeErrorData[maturityAge],
            baselineVariation.variationIdLabel,
          ]
        : [baselineVariation.variationIdLabel]
  }

  if (maturityAge && validCarbonCureSamplesWithMaturityAge < 3) {
    maturityAgeErrorData[maturityAge] =
      maturityAgeErrorData[maturityAge] !== undefined
        ? [
            ...maturityAgeErrorData[maturityAge],
            carbonCureVariation.variationIdLabel,
          ]
        : [carbonCureVariation.variationIdLabel]
  }
  return { errorData, maturityAgeErrorData }
}

/**
 * Get error data for default and interval insufficient sample banners for each mix selection
 * @param {IMixSelection[]} mixSelections the digested mixes
 * @param {ICommissionReportSettings} reportSettings the report settings
 * @returns an object containing the error data for each insufficient samples banner
 */
export const getInsufficientSampleBannerData = (
  mixSelections: IMixSelection[],
  reportSettings: ICommissionReportSettings
) => {
  const errorData: IInsufficientVariationSamples[] = []
  const maturityAgeErrorData: IInsufficientMaturityAgeSamples = {}

  for (let i = 0; i < mixSelections.length; i++) {
    const mixSelection = mixSelections[i]

    const maturityAge = mixSelection.specifiedMaturityAge

    const digestedVariations = mixSelection.digestedVariations
    const baselineVariation = findVariationByType(
      digestedVariations,
      VariationTypes.BASELINE
    )
    const carbonCureVariation = findVariationByType(
      digestedVariations,
      VariationTypes.OPTIMIZED
    )

    const mixDesignSettings = reportSettings.mixDesignSettings[i]
    if (
      !mixDesignSettings.baselineVariation ||
      !mixDesignSettings.carbonCureVariation
    )
      return { errorData, maturityAgeErrorData }

    const baselineSelectedTestCategories =
      mixDesignSettings.baselineVariation.selectedTestCategories
    const carbonCureSelectedTestCategories =
      mixDesignSettings.carbonCureVariation.selectedTestCategories

    if (!baselineVariation || !carbonCureVariation) continue

    const baselineSamples = baselineVariation.samples
    const carbonCureSamples = carbonCureVariation.samples

    const {
      validBaselineSampleCount,
      validCarbonCureSampleCount,
      validBaselineSamplesWithMaturityAge,
      validCarbonCureSamplesWithMaturityAge,
    } = getSufficientSampleCounts(
      baselineSamples,
      carbonCureSamples,
      baselineSelectedTestCategories,
      carbonCureSelectedTestCategories,
      maturityAge
    )
    const args: IGetDataForVariationsWithInsufficientSamplesParams = {
      errorData,
      maturityAgeErrorData,
      validBaselineSampleCount,
      validCarbonCureSampleCount,
      validBaselineSamplesWithMaturityAge,
      validCarbonCureSamplesWithMaturityAge,
      baselineVariation,
      carbonCureVariation,
      maturityAge,
    }
    getDataForVariationsWithInsufficientSamples(args)
  }
  return { errorData, maturityAgeErrorData }
}

export const getSelectedVariation = (
  variationType: VariationTypes,
  mixSelection: IMixSelection
) => {
  const match = mixSelection?.digestedVariations.find(
    variation => variation.orcaVariationType === variationType
  )
  if (!match) return null
  const match2 = mixSelection?.variationOptions.find(
    variationOption => variationOption.id === match?.variationId
  )
  if (!match2) return null
  return match2
}

export const isVariationSampleCountInsufficient = (
  mixSelections: IMixSelection[],
  variationsWithInsufficientSamplesList: IInsufficientVariationSamples[],
  variationType: VariationTypes,
  index: number
) => {
  return variationsWithInsufficientSamplesList.some(
    option =>
      option.id ===
      getSelectedVariation(variationType, mixSelections[index])?.id
  )
}

/*
 * For use with our Testing Summary and Conclusion Summary text.
 *
 * The Material UI TextField component automatically inserts \n in a string when there's a new line entered in the input field.
 * However, Kelowna doubles the escape character when storing string data created with the TextField.
 * This means that the string data received in a response from Kelowna must have the additional escape character
 * removed so that it can properly be displayed in the input.
 */
export const jsonUnescape = (str: string) => {
  return str.replace(/(\\n)/g, '\n')
}

export const translateCementTypes = (cementTypeArray: string[]) => {
  type CementTypeKey = keyof typeof translatedCementTypes
  return cementTypeArray
    .filter(
      (type): type is CementTypeKey =>
        type != null && translatedCementTypes.hasOwnProperty(type)
    )
    .map(type => translatedCementTypes[type])
}

export const translateScmTypes = (scmTypeArray: string[]) => {
  type ScmTypeKey = keyof typeof translatedScmTypes
  return scmTypeArray
    .filter(
      (type): type is ScmTypeKey =>
        type != null && translatedScmTypes.hasOwnProperty(type)
    )
    .map(type => translatedScmTypes[type])
}

export const getMixDetailsProperties = (
  properties: IViewMixSelectionProperty[],
  mixSelection: IMixSelection,
  reportSettings: ICommissionReportSettings
): IViewMixSelectionProperty[] => {
  if (!reportSettings || !mixSelection.mixDesignId) {
    return []
  }
  const mixDesignSettings = reportSettings.mixDesignSettings.find(
    setting => setting.mixDesignId === mixSelection.mixDesignId
  )
  if (!mixDesignSettings) {
    return []
  }
  return properties.filter(property => {
    const includedOnReport =
      mixDesignSettings.mixDesignPropertiesIncludedOnCustomerReport[property.id]
    const value = getViewPropertyValueWithUnit(
      mixSelection,
      property.id,
      reportSettings.isMetric
    )
    return includedOnReport && value
  })
}

export const getValidMixDetailsProperties = (
  properties: IViewMixSelectionProperty[],
  mixSelections: IMixSelection[],
  reportSettings: ICommissionReportSettings
) => {
  // Get all selected properties
  const allSelectedProperties = mixSelections.flatMap(mixSelection =>
    getMixDetailsProperties(properties, mixSelection, reportSettings)
  )

  // Use a Set to track the unique properties for quick lookup
  const validPropertiesSet = new Set(allSelectedProperties)

  // Filter the original properties to maintain the sequence and include only valid properties
  return properties.filter(property => validPropertiesSet.has(property))
}

/**
 * A function to format the report settings in a way that Kelowna expects and return it
 * @param {ICommissionReportSettings} reportSettings
 * @returns formatted report settings in a way that Kelowna will accept
 */
export const getReportObjectForSave = (
  reportSettings: ICommissionReportSettings
) => {
  const reportStatus =
    reportSettings.reportStatus === 'Reviewed'
      ? 'PendingReview'
      : reportSettings.reportStatus
  const reportSettingsBodyObj: ICommissionReportSaveReqBody = {
    divisionId: reportSettings.producerId,
    plantId: reportSettings.plantId,
    status: reportStatus,
    testingStage: reportSettings.testingStage,
    testingSummary: reportSettings.testingSummary,
    conclusionParagraph: reportSettings.conclusionParagraph,
    mixSelection: [],
  }

  reportSettings.mixDesignSettings.forEach(mix => {
    const baselineVariation = {
      ...mix.baselineVariation,
      selectedTestCategories: mix.baselineVariation?.selectedTestCategories.filter(
        category => category !== 'all'
      ),
    }
    const carbonCureVariation = {
      ...mix.carbonCureVariation,
      selectedTestCategories: mix.carbonCureVariation?.selectedTestCategories.filter(
        category => category !== 'all'
      ),
    }
    const mixSelection = {
      mixDesignId: mix.mixDesignId,
      baselineVariation: baselineVariation,
      carbonCureVariation: carbonCureVariation,
      testResultProperties: [...mix.testResults.selectedProperties],
      selectedStrengthHours: [...mix.testResults.selectedStrengthHours],
      selectedStrengthStDevHours: [
        ...mix.testResults.selectedStrengthStDevHours,
      ],
      settings: {
        showOutliers: mix.outlierSettings.show,
        showOutlierReason: mix.outlierSettings.showReason,
        showDifference: mix.testResults.showDifference,
        propertiesToInclude: {
          includeDesignStrength:
            mix.mixDesignPropertiesIncludedOnCustomerReport.designStrength,
          includeWaterCementRatio:
            mix.mixDesignPropertiesIncludedOnCustomerReport.waterCementRatio,
          includeAirContentRange:
            mix.mixDesignPropertiesIncludedOnCustomerReport.airContentRange,
          includeSlump:
            mix.mixDesignPropertiesIncludedOnCustomerReport.slumpRange,
          includeScmTypes:
            mix.mixDesignPropertiesIncludedOnCustomerReport.scmTypes,
          includeScmPercent:
            mix.mixDesignPropertiesIncludedOnCustomerReport.scmPercent,
          includeCementType:
            mix.mixDesignPropertiesIncludedOnCustomerReport.cementTypes,
          includeCementContent:
            mix.mixDesignPropertiesIncludedOnCustomerReport.cementContent,
        },
        frequencyGraphSettings: {
          isSelected: mix.frequencyGraph.isSelected,
          selectedIntervalHours: mix.frequencyGraph.selectedInterval,
          showFailureZone: mix.frequencyGraph.showFailureZone,
          showHistogram: mix.frequencyGraph.showHistogram,
          showBellCurve: mix.frequencyGraph.showBellCurve,
        },
        avgStrengthGraphSettings: {
          isSelected: mix.avgStrengthGraph.isSelected,
          selectedIntervalHours: mix.avgStrengthGraph.selectedInterval,
          showDesignStrength: mix.avgStrengthGraph.showDesignStrength,
        },
      },
    }

    reportSettingsBodyObj.mixSelection.push(mixSelection)
  })
  return reportSettingsBodyObj
}

export const updateOutlierSettings = (
  mixSelection: IMixSelection,
  mixDesignSettings: IReportMixSelectionSettings
) => {
  const hasOutliers =
    getOutlierRows(mixSelection, VariationTypes.BASELINE).length > 0 ||
    getOutlierRows(mixSelection, VariationTypes.OPTIMIZED).length > 0

  mixDesignSettings.outlierSettings = {
    show: hasOutliers ? mixDesignSettings.outlierSettings.show : false,
    showReason: hasOutliers
      ? mixDesignSettings.outlierSettings.showReason
      : false,
  }
}

export const getPDFDate = () => {
  const months = [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec',
  ]

  const date = new Date()
  const year = date.getFullYear()
  const month = months[date.getMonth()]
  const day = String(date.getDate()).padStart(2, '0')

  return `${year}-${month}-${day}`
}

export const getViewOutliersText = (
  mixSelection: IMixSelection,
  reportSettings: ICommissionReportSettings
) => {
  const baselineOutlierIds = getOutlierRows(
    mixSelection,
    VariationTypes.BASELINE
  ).map(outlier => `${outlier.id} (Baseline)`)
  const carbonCureOutlierIds = getOutlierRows(
    mixSelection,
    VariationTypes.OPTIMIZED
  ).map(outlier => `${outlier.id} (CarbonCure)`)

  const allOutliers = [...baselineOutlierIds, ...carbonCureOutlierIds]

  const combinedOutlierIds =
    allOutliers.length > 1
      ? `${allOutliers.slice(0, -1).join(', ')} and ${
          allOutliers[allOutliers.length - 1]
        }`
      : allOutliers[0] || ''

  const OUTLIERS_TEXT_SINGLE = `The following ticket: ${combinedOutlierIds} has been determined 
  to be an outlier and was not considered in this analysis. If you have any questions 
  about the omission of this ticket, please contact ${reportSettings.createdBy}.`

  const OUTLIERS_TEXT_PLURAL = `The following tickets: ${combinedOutlierIds} have been determined 
  to be outliers and were not considered in this analysis. If you have any questions 
  about the omission of these tickets, please contact ${reportSettings.createdBy}.`

  if (allOutliers.length === 0) return ''
  return allOutliers.length > 1 ? OUTLIERS_TEXT_PLURAL : OUTLIERS_TEXT_SINGLE
}

/**
 * Validate the strength/std dev property selections and deselect them when necessary
 *
 * @param tabValue The index of the currently selected tab default at 0.
 * @param reportSettings The report settings object.
 */
export const validateTestResultsSelection = (
  tabValue: number,
  reportSettings: ICommissionReportSettings
) => {
  const mixDesignSettings = reportSettings?.mixDesignSettings[tabValue]

  const isIntervalValid = (interval: NumericIntervals): boolean => {
    if (
      mixDesignSettings.avgStrengthGraph.isSelected &&
      mixDesignSettings.avgStrengthGraph.selectedInterval.includes(interval)
    ) {
      return true
    }

    if (
      mixDesignSettings.frequencyGraph.isSelected &&
      mixDesignSettings.frequencyGraph.selectedInterval === interval
    ) {
      return true
    }

    return false
  }

  // We want to remove invalid intervals from the test results based on the results of the comparison in isIntervalValid
  mixDesignSettings.testResults.selectedStrengthHours = mixDesignSettings?.testResults?.selectedStrengthHours?.filter(
    isIntervalValid
  )

  mixDesignSettings.testResults.selectedStrengthStDevHours = mixDesignSettings?.testResults?.selectedStrengthStDevHours?.filter(
    isIntervalValid
  )
}

/**
 * Handles the deletion of a report, including setting loading states and closing the delete modal.
 * Upon successful deletion, the user is redirected to the Report Library.
 *
 * @param {number | undefined} reportId - The ID of the report to be deleted. If undefined, the function will return early.
 * @param {Dispatch<SetStateAction<boolean>>} setIsLoading - Function to set the loading state, typically used to display a loading spinner or indicator.
 * @param {Dispatch<SetStateAction<boolean>>} setIsDeleteModalOpen - Function to control the visibility of the delete confirmation modal.
 * The modal will be closed after the delete operation completes.
 *
 * @returns {Promise<void>}
 */
export const handleDeleteReport = async (
  reportId: number | undefined,
  setIsLoading: Dispatch<SetStateAction<boolean>>,
  setIsDeleteModalOpen: Dispatch<SetStateAction<boolean>>
) => {
  if (!reportId) return
  setIsLoading(true)
  try {
    await deleteCommissionReport(reportId)
  } catch (error) {
    console.error('Failed to delete the report:', error)
  } finally {
    setIsLoading(false)
    setIsDeleteModalOpen(false)
    window.location.href = '/Producers/ReportLibrary'
  }
}

export const getOutlierRows = (
  mixSelection: IMixSelection,
  variationType: VariationTypes
) => {
  const variation = mixSelection?.digestedVariations?.find(
    variation => variation.orcaVariationType === variationType
  )
  if (!variation) return []

  const outliers = variation.samples.filter(sample => sample.isOutlier)
  const outliersRows: CommissionReportOutlierRow[] = outliers.map(outlier => ({
    id: outlier.ticketId,
    description:
      variationType === VariationTypes.BASELINE
        ? VariationTypes.BASELINE
        : VariationTypes.OPTIMIZEDALT,
    reason:
      outlierReasonOptions[outlier.outlierReason] || outlier.outlierReason,
  }))
  //sort the rows by ticket id
  outliersRows.sort((a, b) => a.id.localeCompare(b.id))
  return outliersRows
}

export const getCommissionReportBaleenUrl = (
  mixSelection: IMixSelection,
  reportSettings: ICommissionReportSettings | undefined
) => {
  const baselineVariation = mixSelection?.digestedVariations?.find(
    variation => variation.orcaVariationType === VariationTypes.BASELINE
  )
  const carbonCureVariation = mixSelection?.digestedVariations?.find(
    variation => variation.orcaVariationType === VariationTypes.OPTIMIZED
  )
  const path = `divisionId=${reportSettings?.producerId}&plantId=${reportSettings?.plantId}&mixGroup=${mixSelection?.mixDesignId}&variationIds=${baselineVariation?.variationId}&variationIds=${carbonCureVariation?.variationId}`
  return path
}

export const getMaturityIntervalOptions = (mixSelection: IMixSelection) => {
  const optionLabels =
    mixSelection?.intervalOptions?.map(option => ({
      value: option,
      label: getIntervalString(option),
    })) ?? []
  return optionLabels
}

export const sortGraphDataAndLabels = (
  optimizedData: number[],
  baselineData: number[],
  intervalLabels: number[]
) => {
  const indices = intervalLabels?.map((_, index) => index)
  indices.sort((a, b) => intervalLabels[a] - intervalLabels[b])

  const sortedOptimizedData = indices?.map(index => optimizedData[index])
  const sortedBaselineData = indices?.map(index => baselineData[index])
  const sortedIntervalLabels = indices?.map(index =>
    getIntervalString(intervalLabels[index])
  )

  return { sortedOptimizedData, sortedBaselineData, sortedIntervalLabels }
}

export const mixHasScmPercent = (mixSelections: IMixSelection[]) => {
  return mixSelections.some(mix => mix.scmPercent != null && mix.scmPercent > 0)
}
