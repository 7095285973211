import React from 'react'
import {
  Typography,
  makeStyles,
  Button,
  Grid,
  Box,
  CircularProgress,
  Backdrop,
} from '@material-ui/core'
import { CSVLink } from 'react-csv'
import GetAppIcon from '@material-ui/icons/GetApp'
import ReportProblemOutlinedIcon from '@material-ui/icons/ReportProblemOutlined'
import theme from '../../../theme/muiTheme'
import hook from './UseDownSystems'
import { downSystemsCsvDownloadHeaders } from '../Constants/SonarConstants'
import DownSystemsTable from './DownSystemsTable/DownSystemsTable'
import { baseColors } from '../../../theme/colors'

const useStyles = makeStyles(() => ({
  refreshInfo: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: '10px',
  },
  header: {
    display: 'flex',
    alignItems: 'center',
  },
  headerIcon: {
    marginRight: '8px',
  },
  lastRefreshed: {
    ...theme.typography.body2,
    fontStyle: 'italic',
    opacity: 0.6,
  },
  viewSubTitle: {
    ...theme.typography.subtitle2,
  },
  tableDiv: {
    height: '100%',
    paddingTop: '16px',
    paddingBottom: '16px',
    width: '100%',
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    paddingTop: '8px',
  },
  downloadButton: { margin: '8px 0px 8px 0px' },
  backDropZIndex: {
    zIndex: 50,
  },
  hyperlink: {
    color: baseColors.primary.light,
    textDecoration: 'none',
  },
}))

const DownSystems: React.FC = () => {
  const classes = useStyles()
  const {
    csvFilename,
    csvRef,
    downSystemsData,
    downSystemsDownloadData,
    handleTableColumnSort,
    lastRefreshed,
    onChangePage,
    onChangeRowsPerPage,
    order,
    orderBy,
    page,
    retrieveDownSystemsDataDownload,
    rowsPerPage,
    tableCount,
    setSnoozeSavedFlag,
    isLoading,
  } = hook.useDownSystems()
  return (
    <>
      <Backdrop open={isLoading} className={classes.backDropZIndex}>
        <CircularProgress color="primary" />
      </Backdrop>
      <Grid container direction="column">
        <Grid item xs className={classes.refreshInfo}>
          <Typography variant="h3" align="left" className={classes.header}>
            <ReportProblemOutlinedIcon className={classes.headerIcon} />
            Down Systems
          </Typography>
          <Typography variant="body2" className={classes.lastRefreshed}>
            Last Refreshed: {lastRefreshed}
          </Typography>
        </Grid>

        <Grid item xs>
          <Typography variant="subtitle2" className={classes.viewSubTitle}>
            View and manage all Down Systems data using the table below. Down
            Systems are systems that Kelowna has <b>not</b> received data from
            in three or more weekdays. Down Systems are not necessarily
            non-operational or even not being used. To learn more about Down
            Systems and why they are important,{' '}
            <a
              href="https://carboncure.atlassian.net/wiki/spaces/ORCA/pages/855212107/Down+Systems+Tab#Definition%3A-Down-System"
              target="_blank"
              rel="noopener noreferrer"
              className={classes.hyperlink}
            >
              click here
            </a>
            {/**/}.
          </Typography>
        </Grid>

        <Grid item xs>
          <Box className={classes.tableDiv}>
            <DownSystemsTable
              count={tableCount}
              downSystemsData={downSystemsData}
              onColumnHeaderClick={handleTableColumnSort}
              onChangePage={onChangePage}
              onChangeRowsPerPage={onChangeRowsPerPage}
              order={order}
              orderBy={orderBy}
              page={page}
              rowsPerPage={rowsPerPage}
              setSnoozeSavedFlag={setSnoozeSavedFlag}
            />
          </Box>
        </Grid>

        <Grid item className={classes.buttonContainer}>
          <Button
            color="primary"
            variant="contained"
            startIcon={<GetAppIcon />}
            className={classes.downloadButton}
            onClick={retrieveDownSystemsDataDownload}
          >
            Download Data
          </Button>
          <CSVLink
            data={downSystemsDownloadData}
            filename={csvFilename}
            headers={downSystemsCsvDownloadHeaders}
            id="csvLinkDownloadDownSystemsData"
            ref={csvRef}
          />
        </Grid>
      </Grid>
    </>
  )
}

export default DownSystems
